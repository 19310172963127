export enum CBox_AdminTodoState {
  INITIAL = "INITIAL",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE"
}

export type CBox_AdminGetTodoListDataParams = {
  page: number;
  pageSize: number;
  lockerIdentifiers: string[];
  equipmentIds: number[];
  states: CBox_AdminTodoState[];
};

export type CBox_AdminGetTodoListDataItem = {
  id: number;
  state: string;
  createdAt: string;
  history: {
    createdAt: string;
    state: string;
    text: string;
  }[];
  reference: {
    type: "LOCKER";
    identifier: string;
    name: string;
  } | {
    type: "EQUIPMENT";
    id: number;
    typeName: string;
    manufacturerSerial: string;
    internalSerial: string;
  }
};

export type CBox_AdminActionCreateTodoRequestData = {
  text: string;
  links: ({
    lockerIdentifier: string;
  } | {
    equipmentId: number;
  })[];
};

export type CBox_AdminActionCreateTodoResponse = undefined;

export type CBox_AdminActionUpdateTodoRequestData = {
  params: {
    id: number;
  };
  body: {
    state: CBox_AdminTodoState;
    text: string;
  };
};

export type CBox_AdminActionUpdateTodoResponse = undefined;
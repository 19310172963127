<div class="flex align-items-center justify-content-between w-full mb-2">
  <h2 class="m-0">To do list</h2>
  <button
    pButton
    size="small"
    class="py-1 px-2 text-xs"
    (click)="createToDo()"
  >
    <span class="material-symbols-rounded md-18">add</span>
    <span class="font-bold">To do</span>
  </button>
</div>
<form [formGroup]="form">
  @for (task of todos(); track $index) {
    <div
      class="expandable-row"
      [attr.aria-expanded]="isRowExpanded(task) ? 'true' : 'false'"
      (click)="toggleRow(task)">
      <div class="header">
        <div class="flex flex-nowrap align-items-center gap-2">
          @if (getLastToDo(task).state === "DONE") {
            <span class="material-symbols-rounded text-green-500 md-18">task_alt</span>
          } @else {
            <span class="material-symbols-rounded text-red-400 md-18">close</span>
          }
          <div class="flex flex-column">
            @switch (task.reference.type) {
              @case ("LOCKER") {
                <span class="text-gray-400 text-xs -mb-1">{{ task.reference.identifier }}</span>
              }

              @case ("EQUIPMENT") {
                <span class="text-gray-400 text-xs -mb-1">{{ task.reference.typeName }}</span>
              }
            }
            <p class="m-0">{{ getFirstToDo(task).text }}</p>
          </div>
        </div>
        <span class="arrow-icon" [ngClass]="{
          'rotate-90': isRowExpanded(task)
        }" class="material-symbols-rounded md-18">keyboard_arrow_right</span>
      </div>
      <div class="content">
        @for (todo of task.history; track $index) {
          <div class="flex flex-nowrap align-items-center justify-content-between w-full my-1 todo">
            <div class="flex flex-nowrap align-items-center gap-2">
              @if (todo.state === "DONE") {
                <span class="material-symbols-rounded text-green-500 md-18">task_alt</span>
              } @else {
                <span class="material-symbols-rounded text-red-400 md-18">close</span>
              }
              <div class="flex flex-column">
                <p class="m-0">{{ todo.text }}</p>
                <p class="m-0 text-xs -mt-1 text-gray-400">{{ todo.createdAt | date: "dd/MM/YYYY HH:mm" }}</p>
              </div>
            </div>
            <p-menu
              [model]="getToDoMenuItems(task, todo)"
              appendTo="body"
              [popup]="true"
              [autoZIndex]="true"
            ></p-menu>
          </div>
        }
        <button
          pButton
          size="small"
          class="py-1 px-2 text-xs ml-3"
          (click)="$event.stopPropagation(); updateToDo(task)"
        >
          <div class="flex align-items-center gap-2">
            <span class="material-symbols-rounded md-18">edit</span>
            <span class="font-bold">Modifică</span>
          </div>
        </button>
      </div>
    </div>
  } @empty {
    <p class="text-gray-400">Nu există to do-uri.</p>
  }
</form>
import { CommonModule, isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, DestroyRef, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_PublicRolePermissionMapping } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_GetLockerShortData } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { CBox_ActionCreateUserResponse, CBox_ActionUpdateOtherUserData, CBox_GetOtherUserResponse, CBox_GetSelfUserResponse } from "@server/services/cbox/public/api/v1/resources/user/types";
import { FormattedPermission } from "app/profile/agents/types";
import { Message } from "primeng/api";
import { AutoFocusModule } from "primeng/autofocus";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MessagesModule } from "primeng/messages";
import { MultiSelectModule } from "primeng/multiselect";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { TableModule } from "primeng/table";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { LockersService } from "src/services/lockers/lockers.service";
import { ProfileService } from "src/services/profile/profile.service";
import { ToastService } from "src/services/toast/toast.service";

@Component({
  selector: "app-cbox-profile-platform-user-update",
  templateUrl: "cbox-profile-platform-user-update.component.html",
  styleUrls: ["./cbox-profile-platform-user-update.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    DividerModule,
    InputGroupModule,
    InputGroupAddonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    AutoFocusModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    ProgressSpinnerModule,
    InputTextareaModule,
    MessagesModule
  ]
})

export class CBoxProfilePlatformUserUpdateComponent implements OnInit {

  userSaveStatus = signal<"waiting" | "fetching" | "saving">("fetching");
  userCreateResponse = signal<CBox_ActionCreateUserResponse | undefined>(undefined);
  userForm: FormGroup;
  userData = signal<CBox_GetSelfUserResponse | undefined>(undefined);
  permissions = signal<FormattedPermission[] | undefined>(undefined);
  roles = signal<CBox_PublicRolePermissionMapping | undefined>(undefined);
  zones = signal<Record<number, { id: string; name: string }>[]>([]);
  zonesFetched = signal(false);
  oldUniqueIdentifier = signal<string | undefined>(undefined);

  showIdentifierWarning = signal(false);
  identifierChangeWarning = signal<Message[]>([{
    severity: 'warn',
    detail:
        `Atentie! Modificarea identificatorului nu este recomandata. Vom folosi noul identificator pentru rapoartele generate ulterior inclusiv pentru actiunile agentului din trecut.`
  }]);

  get lockersForm(): FormGroup {
    return this.userForm.get("lockers") as FormGroup;
  }

  constructor(
    private profileService: ProfileService,
    private api: ApiService,
    private fb: FormBuilder,
    private lockerService: LockersService,
    private config: ConfigurationService,
    private toastService: ToastService,
    private destroyRef: DestroyRef,
    private dialogRef: MatDialogRef<CBoxProfilePlatformUserUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) private userIdentifier: string,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.userForm = this.fb.group({
        name: ["", Validators.required],
        email: ["", Validators.required],
        phone: ["", Validators.required],
        notes: [""],
        zoneId: [undefined, Validators.required],
        role: ["", Validators.required],
        identifier: [{
          value: "",
          disabled: true
        }, Validators.required],
      })
    }

  ngOnInit(): void {
    this.init();
  }

  loadData(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.fetchUserData();
    }
  }

  saveUser(): void {
    this.userSaveStatus.set("saving");
    this.api.patch("backend/users/" + this.userIdentifier, this.getUpdateStructure()).subscribe(() => {
      this.dialogRef.close(true);
    }, (e: HttpErrorResponse) => {
      this.userSaveStatus.set("waiting");
      handlePublicApiError(e, this.toastService);
    });
  }

  private async init(): Promise<void> {
    const roles = await this.config.getRolePermissionMapping()
    this.roles.set(this.filterRoles(roles));
    await this.fetchZones();
    this.loadData();
    this.userForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.showIdentifierWarning.set(value.identifier !== this.oldUniqueIdentifier() && value.customIdentifier);
    });
  }

  private fetchUserData(): void {
    this.api.get<CBox_PublicSuccessResponse<CBox_GetOtherUserResponse>>("backend/users/" + this.userIdentifier).subscribe((response) => {
      const data = response.data;
      this.userForm.patchValue({
        name: data.name,
        identifier: data.identifier,
        email: data.email,
        phone: data.phone,
        notes: data.notes,
        role: data.role,
        zoneId: +data.zoneId,
      });
      this.userSaveStatus.set("waiting");
    }, (e: HttpErrorResponse) => {
      this.dialogRef.close();
      handlePublicApiError(e, this.toastService);
    });
  }

  private async fetchZones(): Promise<void> {
    return new Promise((resolve) => {
      this.api.get<CBox_PublicSuccessResponse<(CBox_GetLockerShortData & {
        zones: {
          id: string;
          name: string;
        }[]
      })[]>>("backend/locker/identifiers?includeZones=true").subscribe((response) => {
        const zones: {id: string; name: string}[] = [];
        for (const locker of response.data) {
          for (const zone of locker.zones) {
            if (!zones.find(z => z.id === zone.id)) {
              zones.push({id: zone.id, name: zone.name});
            }
          }
        }
        this.zones.set(zones);
        this.zonesFetched.set(true);
        resolve();
      }, (e: HttpErrorResponse) => {
        resolve();
        handlePublicApiError(e, this.toastService);
      });
    });
  }

  private filterRoles(roles: CBox_PublicRolePermissionMapping): CBox_PublicRolePermissionMapping {
    return roles.filter((role) => role.canCreate);
  }

  private getUpdateStructure(): CBox_ActionUpdateOtherUserData["body"] {
    const data: any = {
      name: this.userForm.get("name")?.value,
      email: this.userForm.get("email")?.value,
      phone: this.userForm.get("phone")?.value,
      role: this.userForm.get("role")?.value,
      zoneId: this.userForm.get("zoneId")?.value,
      // lockers: {
      //   all: this.userForm.get("lockers.all")?.value,
      //   specific: this.userForm.get("lockers.all")?.value ?
      //       undefined :
      //       this.formatLockers(this.userForm.get("lockers.specific")?.value)
      // },
      notes: this.userForm.get("notes")?.value,
    };

    // if (data.lockers.all) {
    //   delete data.lockers.specific;
    // } else {
    //   delete data.lockers.all;
    // }

    return data;
  }

  // private formatLockers(lockerIds: string[]): CBox_ActionCreateUserData["lockers"]["specific"] {
  //   return lockerIds.map((id) => {
  //     return {
  //       id
  //     }
  //   });
  // }
}
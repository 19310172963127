import { ChangeDetectorRef, Component, DestroyRef, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { CBox_GetLockerBaseData, CBox_GetLockerShortData } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { debounceTime, first } from "rxjs";
import { LockersService } from "src/services/lockers/lockers.service";
import { ProfileService } from "src/services/profile/profile.service";
import { SessionService } from "src/services/session/session.service";
import { CBox_AdminGetProcedureDataResponse, CBox_AdminGetProcedureListData, CBox_AdminGetProcedureListDataItem } from "@server/services/cbox/public/api/v1/resources/internal/procedure/types";
import { CommonModule, isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ToastService } from "src/services/toast/toast.service";
import { CBox_PublicPaginatedResponse, CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { ButtonModule } from "primeng/button";
import { TableModule, TablePageEvent, TableRowCollapseEvent, TableRowExpandEvent } from "primeng/table";
import { IconFieldModule } from "primeng/iconfield";
import { InputIconModule } from "primeng/inputicon";
import { ProgressBarModule } from "primeng/progressbar";
import { InputTextModule } from "primeng/inputtext";
import { MultiSelectModule } from "primeng/multiselect";
import { lockerCleanAddress } from "src/helpers/functions";
import { DropdownModule } from "primeng/dropdown";
import { SkeletonModule } from "primeng/skeleton";
import { MatDialog } from "@angular/material/dialog";
import { CBoxProfilePickupRequestInfoComponent } from "app/profile/requests/pickup/dialogs/info/cbox-profile-pickup-request-info.component";

@Component({
  selector: "app-cbox-profile-admin-locker-procedures-list",
  templateUrl: "./cbox-profile-admin-locker-procedures-list.component.html",
  styleUrls: ["./cbox-profile-admin-locker-procedures-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TableModule,
    ReactiveFormsModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    ProgressBarModule,
    MultiSelectModule,
    DropdownModule,
    SkeletonModule
  ]
})

export class CBoxProfileAdminLockerProceduresListComponent implements OnInit {

  first = signal(0);
  filtersForm: FormGroup;
  lockers = signal<CBox_GetLockerShortData[] | undefined>(undefined);

  procedures = signal<CBox_AdminGetProcedureListDataItem[]>([]);
  fetchingProcedures = signal(true);
  proceduresData = signal<Record<string, CBox_AdminGetProcedureDataResponse>>({});
  fetchingProcedureData = signal<Record<string, true>>({});
  totalCount = signal(0);
  expandedRows = signal<Record<string, boolean>>({});

  procedureTypes = signal([
    {
      label: "Toate",
      value: undefined
    },
    {
      label: "PickUp",
      value: "USER_PICK_UP"
    },
    {
      label: "DropOff",
      value: "USER_DROP_OFF"
    },
    {
      label: "System",
      value: "SYSTEM"
    },
    {
      label: "System update",
      value: "SYSTEM_UPDATE"
    },
    {
      label: "Agent operation",
      value: "AGENT_OPERATION"
    },
    {
      label: "Unknown",
      value: "UNKNOWN"
    },
  ]);

  constructor(
    private profileService: ProfileService,
    private lockersService: LockersService,
    private session: SessionService,
    private fb: FormBuilder,
    private destroyRef: DestroyRef,
    private toastService: ToastService,
    private api: ApiService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.filtersForm = this.fb.group({
        page: [1],
        pageSize: [100],
        id: [[]],
        type: [undefined],
      });
    }

  ngOnInit(): void {
    this.init();
  }

  patchFilters(filters: {[key: string]: unknown}): void {
    this.filtersForm.patchValue({
      ...filters
    });
  }

  pageChanged(event: TablePageEvent): void {
    const page = event.first / event.rows;
    this.filtersForm.patchValue({
      page: page + 1,
      pageSize: event.rows
    }, {
      emitEvent: false
    });
    this.saveFilters();
    this.fetchingProcedures.set(true);
    this.fetchProcedures();
  }

  formatLockerAddress(address: CBox_GetLockerBaseData["addressParts"]): string {
    return lockerCleanAddress(address);
  }

  onRowExpand(procedure: TableRowExpandEvent): void {
    console.log("ROW EXPANDED");
    this.expandedRows.update(rows => {
      return {
        ...rows,
        [procedure.data.uniqueProcedureId]: true
      };
    })
    this.fetchProcedureData(procedure.data);
  }

  onRowCollapse(event: TableRowCollapseEvent): void {
    this.proceduresData.update(proceduresData => {
      delete proceduresData[event.data.uniqueProcedureId];
      return proceduresData;
    });
  }

  openOrderInfo(type: "PICK_UP" | "DROP_OFF", responseId: string): void {
    switch (type) {
      case "PICK_UP":
        this.openPickUpRequestInfo(responseId);
        break;

      default:
        console.error("Unknown procedure type", type);
        break;
    }
  }

  private openPickUpRequestInfo(responseId: string) {
    const dialog = this.dialog.open(CBoxProfilePickupRequestInfoComponent, {
      data: {
        responseId
      },
      minWidth: "min(1200px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.fetchingProcedures.set(true);
      this.fetchProcedures();
    });
  }

  private async init(): Promise<void> {
    this.profileService.setTitle("Listă evenimente locker");

    this.lockers.set(await this.lockersService.getList());

    const defaultFilters = {
      page: 1,
      pageSize: 100,
      id: [],
      type: undefined
    };

    const cachedFilters = this.session.getVal("equipment-list/filters", undefined);

    if (cachedFilters) {
      const parsedFilters = JSON.parse(cachedFilters);

      if (parsedFilters.interval && parsedFilters.interval.length === 2) {
        parsedFilters.interval = parsedFilters.interval.map((dateString: string) => new Date(dateString));
      }

      this.filtersForm.patchValue(
        {
          ...defaultFilters,
          ...parsedFilters,
        },
        { emitEvent: false }
      );
    } else {
      this.filtersForm.patchValue(defaultFilters, { emitEvent: false });
    }

    this.filtersForm.valueChanges
      .pipe(
        debounceTime(500),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.filtersForm.patchValue({ page: 1 }, { emitEvent: false });
        this.saveFilters();
        this.loadProcedures();
      });

    this.loadProcedures();
  }

  private loadProcedures(): void {
    this.procedures.set([]);
    this.fetchingProcedures.set(true);
    if (isPlatformBrowser(this.platformId)) {
      this.fetchProcedures();
    }
  }

  private fetchProcedures(): void {
    this.fetchingProcedures.set(true);
    this.api.get<CBox_PublicPaginatedResponse<CBox_AdminGetProcedureListDataItem>>("backend/internal/procedures", this.getSearchStructure()).subscribe((response) => {
      console.log(response, "ADMIN PROCEDURES LIST RESPONSE");
      this.procedures.set(response.data);
      this.totalCount.set(response.totalCount);
      this.fetchingProcedures.set(false);
    }, (e: HttpErrorResponse) => {
      console.log(e, "EROARE");
      handlePublicApiError(e, this.toastService);
    });
  }

  private fetchProcedureData(procedure: CBox_AdminGetProcedureListDataItem): void {
    this.fetchingProcedureData.update(fetchingProcedureData => {
      return {
        ...fetchingProcedureData,
        [procedure.uniqueProcedureId]: true
      };
    });
    this.api.get<CBox_PublicSuccessResponse<CBox_AdminGetProcedureDataResponse>>("backend/internal/procedures/" + procedure.uniqueProcedureId).subscribe((response) => {
      console.log(response, "PROCEDURE DATA RESPONSE");
      this.proceduresData.update(proceduresData => {
        return {
          ...proceduresData,
          [procedure.uniqueProcedureId]: response.data
        };
      });
      this.fetchingProcedureData.update(fetchingProcedureData => {
        delete fetchingProcedureData[procedure.uniqueProcedureId];
        return fetchingProcedureData;
      });
    }, (e: HttpErrorResponse) => {
      this.fetchingProcedureData.update(fetchingProcedureData => {
        delete fetchingProcedureData[procedure.uniqueProcedureId];
        return fetchingProcedureData;
      });
      this.expandedRows.set({});
      handlePublicApiError(e, this.toastService);
    });
  }

  private saveFilters(): void {
    const filtersValues = this.filtersForm.value;
    const filtersToSave = {
      ...filtersValues,
      interval: filtersValues.interval
        ? filtersValues.interval.map((date: Date) => date?.toISOString())
        : undefined,
    };
    this.session.setVal("equipment-list/filters", JSON.stringify(filtersToSave));
  }

  private getSearchStructure(): CBox_AdminGetProcedureListData {
    return Object.entries({
      'lockerIdentifiers[]': this.filtersForm.value.id,
      page: +this.filtersForm.value.page,
      pageSize: +this.filtersForm.value.pageSize
    })
    .filter(([_, value]) => value?.length || !!value || value != null)
    .reduce((acc, [key, value]) => {
      acc[key as keyof CBox_AdminGetProcedureListData] = value;
      return acc;
    }, {} as any);
  }
}
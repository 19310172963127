import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Inject, InjectionToken, Injector, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { CBoxAdminLockerCreateConfirmDialogComponent } from '../dialogs/create/cbox-admin-locker-create-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ProfileService } from 'src/services/profile/profile.service';
import { lockerCleanAddress } from 'src/helpers/functions';
import { TagModule } from 'primeng/tag';
import { CBoxAdminLockerConfigDialogComponent } from '../dialogs/config/cbox-admin-locker-config-dialog.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CBox_AdminLockerData } from '@server/services/cbox/public/api/v1/resources/internal/locker/types';
import { SessionService } from 'src/services/session/session.service';
import { CheckboxModule } from 'primeng/checkbox';
import { CBoxAdminLockerInfoComponent } from '../dialogs/info/cbox-admin-locker-info.component';
import { TableWithFilters } from 'app/shared/table-with-filters';
import { CBox_GetLockerBaseData, CBox_GetLockerListDataParams } from '@server/services/cbox/public/api/v1/resources/locker/types';

@Component({
  selector: 'app-cbox-admin-lockers-list',
  templateUrl: './cbox-admin-lockers-list.component.html',
  styleUrls: ["./cbox-admin-lockers-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TableModule,
    ProgressBarModule,
    TagModule,
    ReactiveFormsModule,
    MultiSelectModule,
    CheckboxModule
  ]
})

export class CBoxAdminLockersListComponent extends TableWithFilters<CBox_AdminLockerData, CBox_GetLockerListDataParams> implements OnInit {

  lockersList = signal<CBox_AdminLockerData[]>([]);
  filtersForm: FormGroup;
  sessionFiltersKey = "lockers/admin/list";
  localStorageFiltersKey = "lockers/admin/list";
  sessionFiltersToSave = ["lockerIdentifiers", "pageSize", "page", "includePickupCount", "includeDropoffCount", "isActive", "isConfigured"];
  localStorageFiltersToSave = [];
  dataFetchUrl = "internal/lockers";
  defaultFilters = {
    lockerIdentifiers: [],
    pageSize: 100,
    page: 1,
    includePickupCount: false,
    includeDropoffCount: false,
    isActive: false,
    isConfigured: false
  };

  constructor(
    private inj: Injector,
    private profile: ProfileService,
    private session: SessionService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private destroyRef: DestroyRef,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>){
      super(inj);
      this.profile.setTitle("Listă lockere admin");
      this.filtersForm = this.fb.group({
        lockerIdentifiers: [[]],
        pageSize: [100], // default 100
        page: [1], // default 1
        includePickupCount: [false],
        includeDropoffCount: [false],
        isActive: [false],
        isConfigured: [false]
      });
  }

  ngOnInit(): void {
    this.init();
  }

  formatLockerAddress(address: CBox_GetLockerBaseData["addressParts"]): string {
    return lockerCleanAddress(address);
  }

  openLockerDetails(locker: CBox_AdminLockerData): void {
    const dialog = this.dialog.open(CBoxAdminLockerInfoComponent, {
      data: locker.externalIdentifier,
      minWidth: "min(1200px, 100%)"
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.fetchData();
    });
  }

  confirmLockerCreate(): void {
    const dialog = this.dialog.open(CBoxAdminLockerCreateConfirmDialogComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.fetchData();
    });
  }

  editLocker(locker: CBox_AdminLockerData | undefined): void {
    if (!locker) {
      return;
    }
    const dialog = this.dialog.open(CBoxAdminLockerConfigDialogComponent, {
      data: locker,
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.fetchData();
    });
  }

  async init(): Promise<void> {
    this.lockers.set(await this.lockersService.getList());
  }

  getSearchStructure() {
    console.log(Object.entries({
      id: this.filtersForm.value.lockerIdentifiers,
      isActive: !!this.filtersForm.value.isActive,
      isConfigured: !!this.filtersForm.value.isConfigured,
      page: +this.filtersForm.value.page,
      pageSize: +this.filtersForm.value.pageSize
    })
    .filter(([_, value]) => value?.length || !!value)
    .reduce((acc, [key, value]) => {
      acc[key as keyof CBox_GetLockerListDataParams] = value;
      return acc;
    }, {} as CBox_GetLockerListDataParams), "SEARCH STRUCTURE");
    return Object.entries({
      "id[]": this.filtersForm.value.lockerIdentifiers,
      isActive: !!this.filtersForm.value.isActive,
      isConfigured: !!this.filtersForm.value.isConfigured,
      page: +this.filtersForm.value.page,
      pageSize: +this.filtersForm.value.pageSize
    })
    .filter(([_, value]) => value?.length || !!value)
    .reduce((acc, [key, value]) => {
      acc[key as keyof CBox_GetLockerListDataParams] = value;
      return acc;
    }, {} as CBox_GetLockerListDataParams);
  }
}
<form [formGroup]="filtersForm" class="grid align-items-end mt-2">
  <div class="col-12 lg:col-3">
    <span class="font-bold block mb-2">Interval cautare</span>
    <p-calendar
      inputId="interval"
      styleClass="w-full"
      dateFormat="dd/mm/yy"
      selectionMode="range"
      formControlName="interval"
      [showIcon]="true"
      [showOnFocus]="true"
      [placeholder]="'Data de la'"
      [panelStyle]="{
        'height': '439px'
      }"
    ></p-calendar>
  </div>

  <div class="col-12 lg:col-3">
    <span class="font-bold block mb-1">Tip</span>
    <p-multiSelect
      styleClass="w-full"
      optionLabel="label"
      optionValue="type"
      formControlName="types"
      [options]="reportTypes()"
    ></p-multiSelect>
  </div>

  <div class="
    col-12 flex-order-0
    lg:col-offset-3 lg:flex-order-1 lg:col-3
    text-right
  ">
    <p-button
      label="Generează raport"
      icon="pi pi-file-plus"
      (onClick)="generateReport()"
    ></p-button>
  </div>
</form>

<p-table
  styleClass="p-datatable-sm w-full"
  currentPageReportTemplate="{first} - {last} din {totalRecords} rapoarte"
  paginatorPosition="both"
  [totalRecords]="totalCount()"
  [lazy]="true"
  [paginator]="true"
  [rows]="filtersForm.get('pageSize')?.value"
  [value]="data()"
  [rowsPerPageOptions]="[25, 50, 100]"
  [showCurrentPageReport]="true"
  (onPage)="pageChanged($event)"
>
  <ng-template pTemplate="header">
    @if (loading()) {
      <tr>
        <td colspan="8">
          <p-progressBar
            mode="indeterminate"
            styleClass="w-full"
            [style]="{ height: '6px' }"
          ></p-progressBar>
        </td>
      </tr>
    }
    <tr>
      <th>Tip raport</th>
      <th>Data creare</th>
      <th>Perioada</th>
      <th>Status</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-report>
    <tr>
      <td>{{ reportTypeTranslations()[report.type] }}</td>
      <td>{{ report.createdAt | date: "dd MMMM yyyy" }}</td>
      <td>{{ report.interval.start | date: "dd MMMM yyyy" }} - {{ report.interval.end | date: "dd MMMM yyyy" }}</td>
      <td>
        <p-tag
          styleClass="text-xs"
          [severity]="getReportSeverity(report.state)"
        >
          <div class="flex align-items-center gap-2">
            <div class="border-round bg-white" style="width: .5rem; height: .5rem;"></div>
            <span>{{ reportStatesTranslations()[report.state] }}</span>
          </div>
        </p-tag>
      </td>
      <td class="text-right p-0">
        @if (report.state === "IN_PROGRESS" || report.state === "PENDING") {
          <div class="card flex justify-content-end" [style]="{
            'padding-block': '.4375rem'
          }">
            <p-progressSpinner
              class="mx-2"
              strokeWidth="4"
              [style]="{
                width: '1.5rem',
                height: '1.5rem',
              }"
            ></p-progressSpinner>
          </div>
        } @else {
          <div class="card flex gap-2 align-items-center justify-content-end py-1">
            @for (file of report.files; track $index) {
              @if (file.isGenerated) {
                @switch (file.type) {
                  @case ("PDF") {
                    <p-button
                      styleClass="p-2 text-xs"
                      type="button"
                      icon="pi pi-file-pdf"
                      iconPos="left"
                      label="PDF"
                      [loading]="isDownloading(report, 'pdf')"
                      (click)="downloadReport(report, 'pdf')"
                    ></p-button>
                  }

                  @case ("XLSX") {
                    <p-button
                      styleClass="p-2 text-xs"
                      type="button"
                      icon="pi pi-file-excel"
                      iconPos="left"
                      label="Excel"
                      [loading]="isDownloading(report, 'xlsx')"
                      (click)="downloadReport(report, 'xlsx')"
                    ></p-button>
                  }
                }
              }
              }
          </div>
        }
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    @if (!loading()) {
      <tr>
        <td colspan="8">
          <p class="text-gray-400 text-center text-lg font-bold">Nu exista rapoarte.</p>
        </td>
      </tr>
    }
  </ng-template>
</p-table>
import {
  Component,
  Inject,
  InjectionToken,
  OnInit,
  PLATFORM_ID,
  signal
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from "@angular/material/dialog";
import {
  CommonModule,
  isPlatformBrowser
} from "@angular/common";
import {
  HttpErrorResponse,
  HttpParams
} from "@angular/common/http";
import { CBox_ActionCreateUserData } from "@server/services/cbox/public/api/v1/resources/user/types";
import { CBox_GetLockerShortData } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { CBox_PublicRolePermissionMapping } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { ProfileService } from "src/services/profile/profile.service";
import { ToastService } from "src/services/toast/toast.service";
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { CheckboxModule } from "primeng/checkbox";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { InputTextareaModule } from "primeng/inputtextarea";
import { InputTextModule } from "primeng/inputtext";
import { ReactiveFormsModule } from "@angular/forms";
import { MessagesModule } from "primeng/messages";
import { MultiSelectModule } from "primeng/multiselect";
import { IconFieldModule } from "primeng/iconfield";
import { InputIconModule } from "primeng/inputicon";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_ActionCreateAgentData, CBox_ActionCreateAgentResponse } from "@server/services/cbox/public/api/v1/resources/agent/types";
import { Message } from "primeng/api";
import { CBox_PublicConfigurationIdentifierField } from "@server/services/cbox/public/api/v1/enforcers/company_config/types";
import { AutoFocusModule } from "primeng/autofocus";

@Component({
  selector: "app-cbox-profile-platform-searchable-user-create",
  templateUrl: "./cbox-profile-platform-searchable-user-create.component.html",
  styleUrls: ["./cbox-profile-platform-searchable-user-create.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,

    // PrimeNG
    ButtonModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    ProgressSpinnerModule,
    InputTextareaModule,
    InputTextModule,
    MessagesModule,
    IconFieldModule,
    InputIconModule,
    AutoFocusModule
  ]
})
export class CBoxProfilePlatformSearchableUserCreateComponent implements OnInit {
  /** FormGroup that manages user data. */
  userFormGroup: FormGroup;

  /** Signals for UI states. */
  userCreateStatus = signal<"success" | "error" | "unknown">("unknown");
  agentCreateStatus = signal<"success" | "error" | "unknown">("unknown");
  foundUser = signal<boolean>(false);
  userData = signal<any | undefined>(undefined);
  availablePermissions = signal<any[] | undefined>(undefined);
  availableRoles = signal<CBox_PublicRolePermissionMapping | undefined>(undefined);
  availableZones = signal<Array<{ id: string; name: string }>>([]);
  zonesLoaded = signal(false);

  userSaveRetryAllowed = signal(true);
  agentSaveRetryAllowed = signal(true);

  initializing = signal(true);

  /** Controls whether user identifier is mandatory. */
  requiresIdentifier = signal<boolean | undefined>(undefined);
  isSearchMandatory = signal(false);
  isAgentSearchMandatory = signal(false);

  /** Indicates whether we are currently searching or creating. */
  isSearching = signal(false);
  isCreating = signal(false);

  /** Animation trigger used in the template. Increment to re-run the animation. */
  paneAnimationState = signal(0);

  autoGeneratedIdentifierMessage = signal<Message[]>([{
    severity: "info",
    detail:
        `Identificatorul agentului va fi generat automat de sistemul nostru.
        Acest cod va fi folosit în sistem pentru afișarea informațiilor
        legate de acest agent. În cazul în care doriți să atribuiți un
        identificator personalizat, vă rugăm să bifați căsuța din formular.`
  }]);

  userAgentMessage = signal<Message[]>([{
    severity: "info",
    detail:
        `In cazul in care doriti ca acest utilizator sa aiba acces la lockere bifati casuta de mai jos`
  }]);

  loading = signal(false);

  constructor(
    private profileService: ProfileService,
    private api: ApiService,
    private fb: FormBuilder,
    private userService: ProfileService,
    private config: ConfigurationService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxProfilePlatformSearchableUserCreateComponent>,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>
  ) {
    // Initialize form
    this.userFormGroup = this.fb.group({
      name: ["", Validators.required],
      email: ["", Validators.required],
      phone: ["", Validators.required],
      notes: [""],
      zoneId: [undefined, Validators.required],
      role: ["", Validators.required],
      identifier: ["", Validators.required],
      customIdentifier: [false],
      shouldCreateAgent: [true]
    });
  }

  ngOnInit(): void {
    this.initializeComponent();
  }

  /**
   * Initialization logic for the component.
   */
  private async initializeComponent(): Promise<void> {
    // Load roles from config
    const roles = await this.config.getRolePermissionMapping();
    const identifierRequirements = await this.config.getConfigKey("user.identifier.requirements") as CBox_PublicConfigurationIdentifierField;
    const agentIdentifierRequirements = await this.config.getConfigKey("agent.identifier.requirements") as CBox_PublicConfigurationIdentifierField;
    this.requiresIdentifier.set(identifierRequirements.isSearchable);
    this.isSearchMandatory.set(identifierRequirements.isSearchMandatory);
    this.isAgentSearchMandatory.set(agentIdentifierRequirements.isSearchMandatory);
    console.log("Search mandatory: ", this.isSearchMandatory());
    console.log("Agent search mandatory: ", this.isAgentSearchMandatory());
    // Filter roles that can be created by this user
    this.availableRoles.set(this.filterRoles(roles));

    // Load external data from server
    if (isPlatformBrowser(this.platformId)) {
      this.loadZones();
    }
    this.initializing.set(false);
  }

  /**
   * Loads zones data from the backend.
   */
  private loadZones(): void {
    this.api.get<CBox_PublicSuccessResponse<(CBox_GetLockerShortData & {
      zones: { id: string; name: string }[];
    })[]>>("backend/locker/identifiers?includeZones=true")
      .subscribe({
        next: (response) => {
          const foundZones: Array<{ id: string; name: string }> = [];
          for (const locker of response.data) {
            for (const zone of locker.zones) {
              if (!foundZones.some((z) => z.id === zone.id)) {
                foundZones.push({ id: zone.id, name: zone.name });
              }
            }
          }
          this.availableZones.set(foundZones);
          this.zonesLoaded.set(true);
        },
        error: (err: HttpErrorResponse) => {
          handlePublicApiError(err, this.toastService);
        }
      });
  }

  /**
   * Filter roles to only those allowed to be created.
   */
  private filterRoles(roles: CBox_PublicRolePermissionMapping): CBox_PublicRolePermissionMapping {
    return roles.filter((role) => role.canCreate);
  }

  /**
   * Handler for searching a user by identifier.
   */
  onSearchUser(): void {
    this.isSearching.set(true);
    const identifierValue = this.userFormGroup.get("identifier")?.getRawValue();

    const params = new HttpParams().set("identifier", identifierValue);
    this.api.get<CBox_PublicSuccessResponse<any>>("backend/users/external/search", params)
      .subscribe({
        next: (response) => {
          this.isSearching.set(false);
          this.foundUser.set(response.data);
          this.userData.set(response.data);
          this.userFormGroup.patchValue(response.data);
        },
        error: (err: HttpErrorResponse) => {
          this.isSearching.set(false);
          if (this.isSearchMandatory()) {
            handlePublicApiError(err, this.toastService);
          } else {
            this.userFormGroup.get("shouldCreateAgent")?.patchValue(false);
            this.foundUser.set(true);
            this.userData.set({});
            this.userFormGroup.get("identifier")?.disable();
          }
        }
      });
  }

  /**
   * Handler for saving user data.
   */
  onSaveUser(): void {
    const shouldCreateAgent = !!this.userFormGroup.get("shouldCreateAgent")?.value;
    this.loading.set(true);

    const payload = this.getUserCreateStructure();
    this.api.post("backend/users", payload).subscribe({
      next: () => {
        this.userCreateStatus.set("success");
        if (!!this.userFormGroup.get("shouldCreateAgent")?.value) {
          this.loading.set(false);
          this.isCreating.set(true);
          this.createAgent();
        } else {
          this.dialogRef.close();
          this.toastService.showSuccessToast("Confirmare", "Utilizatorul a fost creat cu succes.");
        }
      },
      error: (err: HttpErrorResponse) => {
        this.loading.set(false);
        this.isCreating.set(false);
        this.userCreateStatus.set("error");
        handlePublicApiError(err, this.toastService);
      }
    });
  }

  /**
   * Handler for retrying user save in case of error.
   */
  retryUserSave(): void {
    this.userSaveRetryAllowed.set(false);
    this.userCreateStatus.set("unknown");
    this.onSaveUser();
  }

  retryAgentSave(): void {
    this.agentSaveRetryAllowed.set(false);
    this.agentCreateStatus.set("unknown");
    this.createAgent();
  }

  private createAgent(): void {
    const agentPayload = this.getAgentCreateStructure();
    this.api.post<CBox_PublicSuccessResponse<CBox_ActionCreateAgentResponse>>("backend/agents", agentPayload).subscribe((response) => {
      this.agentCreateStatus.set("success");
    }, (e: HttpErrorResponse) => {
      this.agentCreateStatus.set("error");
      handlePublicApiError(e, this.toastService);
    });
  }

  /**
   * Builds the data payload for user creation.
   */
  private getUserCreateStructure(): CBox_ActionCreateUserData {
    const rawValue = this.userFormGroup.value;
    return {
      name: rawValue.name,
      email: rawValue.email,
      phone: rawValue.phone,
      role: rawValue.role,
      zoneId: rawValue.zoneId,
      identifier: rawValue.identifier,
      notes: rawValue.notes
    };
  }

  private getAgentCreateStructure(): CBox_ActionCreateAgentData["body"] {
    const data: CBox_ActionCreateAgentData["body"] = {
      agent: {
        name: this.userFormGroup.get("name")?.value,
        phone: this.userFormGroup.get("phone")?.value,
        email: this.userFormGroup.get("email")?.value,
        identifier: this.userFormGroup.get("identifier")?.value,
        zoneId: this.userFormGroup.get("zoneId")?.value,
      },
      permittedLockers: {
        all: true
      }
    }

    return data;
  }

  /**
   * Let the user toggle the custom identifier checkbox.
   */
  onToggleCustomIdentifier(): void {
    const identifierCtrl = this.userFormGroup.get("identifier");
    const usingCustomIdentifier = this.userFormGroup.get("customIdentifier")?.value;
    if (usingCustomIdentifier) {
      identifierCtrl?.enable();
    } else {
      identifierCtrl?.disable();
    }
  }

  /**
   * Reset the modal state.
   */
  reset(): void {
    this.foundUser.set(false);
    this.userFormGroup.reset();
    this.userFormGroup.enable();
  }
}
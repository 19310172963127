<div class="dialog-header flex align-items-center justify-content-between w-full">
  @if (fetching() === "done") {
    <div class="flex flex-column">
      <small class="-mb-2 text-gray-400">#{{ locker()?.id }}</small>
      <h2 class="flex align-items-center gap-2">
        {{ locker()?.name }}
        @if (locker()?.state === "ACTIVE") {
          <p-tag class="mb-1" severity="success">ACTIV</p-tag>
        } @else if (locker()?.state === "INACTIVE") {
          <p-tag class="mb-1" severity="danger">INACTIV</p-tag>
        }
      </h2>
      <small class="-mt-1 text-sm">{{ formatLockerAddress(locker()?.addressParts) }}</small>
      <small class="flex align-items-center gap-2">
        <span class="material-symbols-rounded md-18">print</span>
        @let printerState = locker()?.options?.printer?.state;
        <p class="m-0" [ngClass]="{
          'text-yellow-500': printerState === 'UNKNOWN' || printerState === 'OUT_OF_PAPER',
          'text-red-500': printerState === 'NOT_OK',
          'text-green-500': printerState === 'OK'
        }">
          @if (printerState === "UNKNOWN") {
            Stare necunoscută
          } @else if (printerState === "NOT_OK") {
            Defect
          } @else if (printerState === "OK") {
            Functional
          } @else if (printerState === "OUT_OF_PAPER") {
            Fară hârtie
          }
        </p>
        <p-divider styleClass="h-1rem py-1 m-0" layout="vertical"></p-divider>
        <span class="material-symbols-rounded md-18">qr_code_scanner</span>
        @let scannerState = locker()?.options?.scanner?.state;
        <p class="m-0" [ngClass]="{
          'text-yellow-500': scannerState === 'UNKNOWN',
          'text-red-500': scannerState === 'NOT_OK',
          'text-green-500': scannerState === 'OK'
        }">
          @if (scannerState === "UNKNOWN") {
            Stare necunoscută
          } @else if (scannerState === "NOT_OK") {
            Defect
          } @else if (scannerState === "OK") {
            Functional
          }
        </p>
      </small>
    </div>
    <p-button
      size="small"
      mat-dialog-close
      icon="pi pi-times"
      severity="secondary"
      [rounded]="true"
      [text]="true"
    />
  } @else if (fetching() === "fetching") {
    <div class="flex flex-column">
      <p-skeleton borderRadius="100vw" height=".75rem" width="5rem"></p-skeleton>
      <p-skeleton borderRadius="100vw" width="30rem" height="1.5rem" [style]="{
        'margin-block': '.25rem'
      }"></p-skeleton>
      <p-skeleton borderRadius="100vw" width="25rem" height=".75rem"></p-skeleton>
      <p-skeleton borderRadius="100vw" width="23rem" height=".75rem" [style]="{
        'margin-top': '.25rem'
      }"></p-skeleton>
    </div>
    <p-skeleton shape="circle" width="3rem" height="3rem"></p-skeleton>
  }
</div>
<mat-dialog-content>
  @if (fetching() === "done") {
    <div class="grid">
      @for (boxes of locker()?.boxes | keyvalue; track $index) {
        <div class="col-12 md:col-4">
          <div class="card h-full shadow-1 flex flex-column align-items-between gap-2 p-4">
            <div class="flex align-items-center justify-content-between gap-2 w-full">
              <div class="flex align-items-center gap-2">
                <p-avatar
                  shape="circle"
                  styleClass="p-1"
                  [style]="{
                    'background-color': doorLabels[boxes.key].color,
                    'color': 'var(--surface-0)',
                  }"
                >
                  <span class="material-symbols-rounded">{{ doorLabels[boxes.key].icon }}</span>
                </p-avatar>
                <p class="m-0 text-lg">Usi {{ doorLabels[boxes.key].label }}</p>
              </div>
              <p class="m-0 text-3xl font-bold">{{ getBoxesCount(boxes.key) }}</p>
            </div>
            <p-table [value]="boxes.value" styleClass="p-datatable-sm">
              <ng-template pTemplate="body" let-box>
                <tr>
                  <td>{{ box.size }}</td>
                  <td class="text-right font-bold text-lg pr-1">{{ box.count }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      }

      <!-- <div class="col-12 md:col-6 lg:col-3">
        <div class="card h-full shadow-1 flex flex-column align-items-center gap-2 p-4">
          <div class="flex align-items-center justify-content-between gap-2 w-full">
            <div class="flex align-items-center gap-2">
              <p-avatar
                shape="circle"
                styleClass="p-1"
              >
                <span class="material-symbols-rounded">settings</span>
              </p-avatar>
              <p class="m-0 text-lg">Stare componente</p>
            </div>
          </div>
          <div class="flex align-items-center gap-2 w-full">
            <div class="flex flex-column align-items-center w-full">
              <span class="material-symbols-rounded">print</span>
              <p class="m-0">Imprimantă</p>
              @let printerState = locker()?.options?.printer?.state;
              @if (printerState === "UNKNOWN") {
                <p-tag value="Necunoscut" severity="warning" [rounded]="true"></p-tag>
              } @else if (printerState === "NOT_OK") {
                <p-tag value="Indisponibil" severity="danger" [rounded]="true"></p-tag>
              } @else if (printerState === "OK") {
                <p-tag value="Disponibil" severity="success" [rounded]="true"></p-tag>
              } @else if (printerState === "OUT_OF_PAPER") {
                <p-tag value="Fara hartie" severity="warning" [rounded]="true"></p-tag>
              }
            </div>
            <p-divider layout="vertical"></p-divider>
            <div class="flex flex-column align-items-center w-full">
              <span class="material-symbols-rounded">qr_code_scanner</span>
              <p class="m-0">Scanner</p>
              @let scannerState = locker()?.options?.scanner?.state;
              @if (printerState === "UNKNOWN") {
                <p-tag value="Necunoscut" severity="warning" [rounded]="true"></p-tag>
              } @else if (printerState === "NOT_OK") {
                <p-tag value="Indisponibil" severity="danger" [rounded]="true"></p-tag>
              } @else if (printerState === "OK") {
                <p-tag value="Disponibil" severity="success" [rounded]="true"></p-tag>
              }
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div *appRights="[permissions.viewLockerPickUps]">
      <h2>Comenzi PickUp</h2>
      <p-accordion>
        @for (orders of locker()?.requests?.pickUp | keyvalue; track $index) {
          <p-accordionTab [header]="pickUpOrderLabels[orders.key].label + ' (' + orders.value.list.length + ')'">
            <p-table [value]="orders.value.list" styleClass="p-datatable-sm p-datatable-striped">
              <ng-template pTemplate="header">
                <tr>
                  <th>AWB</th>
                  <th>Referință unică</th>
                  <th>Stare</th>
                  <th>Rezervat pana la</th>
                  <th>Ridicare posibila pana la</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-order>
                <tr class="cursor-pointer" (click)="openPickUpRequestInfo(order)">
                  <td>{{ order.awb }}</td>
                  <td>{{ order.uniqueReference }}</td>
                  <td>
                    {{ order.state.description }}<br>
                    {{ order.state.occurredAt | date: "dd/MM/YYYY HH:mm" }}
                  </td>
                  <td>{{ order.reservedUntil | date: "dd/MM/YYYY HH:mm" }}</td>
                  <td>{{ order.pickUpPossibleUntil | date: "dd/MM/YYYY HH:mm" }}</td>
                </tr>
              </ng-template>
            </p-table>
          </p-accordionTab>
        }
      </p-accordion>
    </div>

    <div *appRights="[permissions.viewLockerDropOffs]">
      <h2>Comenzi DropOff</h2>
      <p-accordion>
        @for (orders of locker()?.requests?.dropOff | keyvalue; track $index) {
          <p-accordionTab [header]="dropOffOrderLabels[orders.key].label + ' (' + orders.value.list.length + ')'">
            <p-table [value]="orders.value.list" styleClass="p-datatable-sm p-datatable-striped">
              <ng-template pTemplate="header">
                <tr>
                  <th>AWB</th>
                  <th>Referință unică</th>
                  <th>Stare</th>
                  <th>Rezervat pana la</th>
                  <th>Predare posibila pana la</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-order>
                <tr class="cursor-pointer" (click)="openDropOffRequestInfo(order)">
                  <td>{{ order.awb }}</td>
                  <td>{{ order.uniqueReference }}</td>
                  <td>
                    {{ order.state.description }}<br>
                    {{ order.state.occurredAt | date: "dd/MM/YYYY HH:mm" }}
                  </td>
                  <td>{{ order.reservedUntil | date: "dd/MM/YYYY HH:mm" }}</td>
                  <td>{{ order.dropOffPossibleUntil | date: "dd/MM/YYYY HH:mm" }}</td>
                </tr>
              </ng-template>
            </p-table>
          </p-accordionTab>
        }
      </p-accordion>
    </div>
  } @else if (fetching() === "fetching") {
    <div class="grid">
      <p-skeleton class="col-12 md:col-4" height="14.8125rem"></p-skeleton>
      <p-skeleton class="col-12 md:col-4" height="14.8125rem"></p-skeleton>
      <p-skeleton class="col-12 md:col-4" height="14.8125rem"></p-skeleton>
    </div>

    <p-skeleton width="7rem" height="1.5rem" styleClass="my-3"></p-skeleton>
    <p-skeleton class="w-full" height="3.625rem"></p-skeleton>
    <p-skeleton class="w-full" height="3.625rem" styleClass="mt-1"></p-skeleton>
    <p-skeleton class="w-full" height="3.625rem" styleClass="mt-1"></p-skeleton>

    <p-skeleton width="7rem" height="1.5rem" styleClass="my-3"></p-skeleton>
    <p-skeleton class="w-full" height="3.625rem"></p-skeleton>
    <p-skeleton class="w-full" height="3.625rem" styleClass="mt-1"></p-skeleton>
  }
</mat-dialog-content>

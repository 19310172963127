import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, InjectionToken, isDevMode, PLATFORM_ID } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ConfigurationService } from 'src/services/configuration/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationGuard implements CanActivate {

  constructor(
    private configurationService: ConfigurationService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    try {
      if (!this.configurationService.configuration()) {
        await this.configurationService.fetch();
      }
      return true;
    } catch (error) {
      return this.router.createUrlTree(['/login']);
    }
  }
}
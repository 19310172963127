<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>
    Editeaza to do
  </h2>
  <p-button
    mat-dialog-close
    size="small"
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <form class="grid" [formGroup]="form">
    <div class="col-12">
      <span class="font-bold block mb-1">State</span>
      <p-dropdown
        formControlName="state"
        optionLabel="label"
        optionValue="value"
        styleClass="w-full"
        appendTo="body"
        [showClear]="true"
        [options]="states()"
      ></p-dropdown>
    </div>

    <div class="col-12">
      <span class="block font-bold mb-1">Detalii</span>
      <textarea
        class="w-full"
        rows="5"
        formControlName="text"
        placeholder="Descrieți task-ul"
        pInputTextarea
      ></textarea>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <p-button
    label="Adaugă"
    (click)="update()"
    [disabled]="!form.valid"
    [loading]="loading()"
  ></p-button>
</mat-dialog-actions>
@if (loading()) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}
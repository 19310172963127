import { CommonModule } from "@angular/common";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Component, Inject, OnInit, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { AvatarModule } from "primeng/avatar";
import { ButtonModule } from "primeng/button";
import { DividerModule } from "primeng/divider";
import { MessagesModule } from "primeng/messages";
import { TagModule } from "primeng/tag";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { CBox_GetPickUpDataResponse, CBox_GetPickUpListItemDataResponse, CBox_RequestActionEnum } from "@server/services/cbox/public/api/v1/resources/pick_up/types";
import { TimelineModule } from "primeng/timeline";
import { TableModule } from "primeng/table";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CardModule } from "primeng/card";
import { copyString, lockerCleanAddress } from "src/helpers/functions";
import { MatTooltipModule } from "@angular/material/tooltip";
import { first } from "rxjs";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { ToastService } from "src/services/toast/toast.service";
import { PermissionNamesEnum } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { PermissionDirective } from "src/directives/permission.directive";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ConfirmationService } from "primeng/api";
import { SkeletonModule } from "primeng/skeleton";
import { TooltipModule } from "primeng/tooltip";
import { CBoxProfileLockerDataComponent } from "app/profile/lockers/data/cbox-profile-locker-data.component";
import { CBox_GetLockerDataResponse } from "@server/services/cbox/public/api/v1/resources/locker/types";

@Component({
  selector: "app-cbox-profile-dropoff-request-info",
  templateUrl: "./cbox-profile-dropoff-request-info.component.html",
  styleUrls: ["./cbox-profile-dropoff-request-info.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TagModule,
    AvatarModule,
    TableModule,
    TimelineModule,
    DividerModule,
    MessagesModule,
    MatDialogContent,
    MatDialogClose,
    CardModule,
    MatTooltipModule,
    PermissionDirective,
    ProgressSpinnerModule,
    ConfirmPopupModule,
    SkeletonModule,
    TooltipModule
  ],
  providers: [ConfirmationService]
})

export class CBoxProfileDropOffRequestInfoComponent implements OnInit {

  orderData = signal<CBox_GetPickUpDataResponse | undefined>(undefined);
  fetching = signal<"fetching" | "success" | "error">("fetching");
  fetchingCode = signal(false);
  fetchRetryTimer = 0;
  fetchRetryInterval?: NodeJS.Timeout;
  lockerData = signal<CBox_GetLockerDataResponse | undefined>(undefined);
  fetchingLockerData = signal<"fetching" | "error" | "success">("fetching");

  boxReasons = signal<Record<string, string>>({
    "LARGER_BOX_DUE_TO_AVAILABILITY": "Mai mare datorita disponibilității",
    "LARGER_BOX_DUE_TO_PACKAGE_SIZE": "Mai mare datorita dimensiunii",
    "PROJECTED_BOX_USED": "Dimensiunea solicitată",
    "NONE": ""
  });

  reservationMessage = signal<any>([]);

  awbCopied = signal<boolean>(false);
  awbCopyTimeout?: NodeJS.Timeout;

  publicLinkCopied = signal<boolean>(false);
  publicLinkCopyTimeout?: NodeJS.Timeout;

  permissions = PermissionNamesEnum;
  showCode = signal(false);
  showCodeTimeout?: NodeJS.Timeout;

  sendingEmail = signal(false);
  sendingSMS = signal(false);
  smsLimit = signal(2);
  emailLimit = signal(2);
  today = signal(new Date());

  actionsEnum = CBox_RequestActionEnum;

  constructor(
    private dialog: MatDialog,
    private api: ApiService,
    private configuration: ConfigurationService,
    private dialogRef: MatDialogRef<CBoxProfileDropOffRequestInfoComponent>,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    @Inject(MAT_DIALOG_DATA) private pickUpOrder: CBox_GetPickUpListItemDataResponse
  ) {}

  ngOnInit(): void {
    this.init();
  }

  fetchPickupOrderInfo(withCode = false): void {
    this.toastService.clear();
    let params = new HttpParams();
    params = params.append("responseId", this.pickUpOrder.responseId);
    if (this.configuration.hasAccess([PermissionNamesEnum.viewPickUpOpenCode]) && withCode) {
      params = params.append("includeCode", "true");
    }
    if (this.configuration.hasAccess([PermissionNamesEnum.systemAdminAll])) {
      params = params.append("includeActions", "true");
    }
    this.api.get<CBox_PublicSuccessResponse<CBox_GetPickUpDataResponse>>("backend/request/pick-up", params).subscribe((response) => {
      console.log(response, "PICK UP REQUEST INFO RESPONSE");
      this.orderData.set(response.data);
      this.fetchLockerData();
      this.fetching.set("success");
      this.fetchingCode.set(false);
      if (withCode) {
        if (!response.data.openCode) {
          this.toastService.showErrorToast("Eroare", "Codul de acces nu a putut fi preluat!");
          this.showCode.set(false);
          clearTimeout(this.showCodeTimeout);
          return;
        }

        this.toggleCode();
      }
    }, (e: HttpErrorResponse) => {
      this.fetching.set("error");
      handlePublicApiError(e, this.toastService);
    });
  }

  fetchLockerData(): void {
    this.api.get<CBox_PublicSuccessResponse<CBox_GetLockerDataResponse>>("backend/locker?name=" + this.orderData()?.locker.name).subscribe((locker) => {
      this.lockerData.set(locker.data);
      this.fetchingLockerData.set("success");
    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
      this.fetchingLockerData.set("error");
    });
  }

  lockerCleanAddress(addressParts: CBox_GetLockerDataResponse["addressParts"]): string {
    return lockerCleanAddress(addressParts);
  }

  confirmRequestCancel(): void {
  //   const dialog = this.dialog.open(CBoxProfilePickUpRequestCancelDialogComponent, {
  //     data: this.orderData(),
  //     width: "min(400px, 100%)",
  //     autoFocus: false
  //   });

  //   dialog.afterClosed().pipe(first()).subscribe((deleted: boolean) => {
  //     if (deleted) {
  //       this.dialogRef.close({
  //         orderDeleted: deleted
  //       });
  //       this.toastService.showSuccessToast("Confirmare", "Comanda a fost ștearsă cu succes!");
  //     }
  //   });
  }

  confirmRequestReactivate(): void {
  //   const dialog = this.dialog.open(CBoxProfilePickUpRequestReactivateDialogComponent, {
  //     data: this.orderData()?.responseId,
  //     width: "min(400px, 100%)",
  //     autoFocus: false
  //   });

  //   dialog.afterClosed().pipe(first()).subscribe((reactivated: boolean) => {
  //     if (reactivated) {
  //       this.fetchPickupOrderInfo();
  //       this.toastService.showSuccessToast("Confirmare", "Comanda a fost reactivată cu succes!");
  //     }
  //   });
  }

  confirmRequestExtend(): void {
  //   const dialog = this.dialog.open(CBoxProfilePickUpRequestExtendDialogComponent, {
  //     data: this.orderData()?.responseId,
  //     width: "min(400px, 100%)",
  //     autoFocus: false
  //   });

  //   dialog.afterClosed().pipe(first()).subscribe((extended: boolean) => {
  //     if (extended) {
  //       this.fetchPickupOrderInfo();
  //       this.toastService.showSuccessToast("Confirmare", "Termenul de ridicare al comenzii a fost extins cu succes!");
  //     }
  //   });
  }

  extendRequest(): void {
  //   const dialog = this.dialog.open(CBoxProfilePickUpRequestExtendDialogComponent, {
  //     data: this.orderData()?.responseId,
  //     width: "min(400px, 100%)",
  //     autoFocus: false
  //   });

  //   dialog.afterClosed().pipe(first()).subscribe((extended: boolean) => {
  //     if (extended) {
  //       this.dialogRef.close({
  //         orderDeleted: extended
  //       });
  //       this.toastService.showSuccessToast("Confirmare", "Termenul comenzii a fost extins cu succes!");
  //     }
  //   });
  }

  hasActiveFlags(): boolean {
    if (!this.orderData()?.flags) {
      return false;
    }
    const flagValues = Object.values(this.orderData()?.flags!);
    return flagValues.some(flag => flag === true);
  }

  formatTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days === 0 && hours === 0) {
      return `${minutes === 1 ? "1 minut" : minutes + " minute"}`;
    }

    if (days > 0) {
      if (hours > 0) {
        return `${days > 1 ? days + " zile" : "1 zi"} si ${hours % 24 > 1 ? hours % 24 + " ore" : "o oră"}`;
      }
    } else if (hours > 0) {
      return `aproximativ ${hours > 1 ? hours + "ore" : "1 oră"}`;
    }

    return "";
  }

  splitted(value: string | undefined): string[] {
    if (!value) {
      return [];
    }

    return value.split("");
  }

  copyAwb(): void {
    if (this.awbCopyTimeout) {
      clearTimeout(this.awbCopyTimeout);
    }

    copyString(this.pickUpOrder.awb);
    this.awbCopied.set(true);
    this.awbCopyTimeout = setTimeout(() => {
      this.awbCopied.set(false);
    }, 500);
  }

  copyPublicLink(): void {
    if (this.publicLinkCopyTimeout) {
      clearTimeout(this.publicLinkCopyTimeout);
    }

    const link = "https://cbox.ro/c/" + this.orderData()?.publicReference;
    copyString(link);
    this.publicLinkCopied.set(true);
    this.publicLinkCopyTimeout = setTimeout(() => {
      this.publicLinkCopied.set(false);
    }, 500);
}

  retryFetch(): void {
    this.fetchPickupOrderInfo();
    if (this.fetchRetryInterval) {
      clearTimeout(this.fetchRetryInterval);
    }

    let retryAgainAfter = 10; // seconds
    this.fetchRetryTimer = retryAgainAfter;

    this.fetchRetryInterval = setInterval(() => {
      this.fetchRetryTimer = this.fetchRetryTimer - 1;
      if (this.fetchRetryTimer === 0) {
        clearInterval(this.fetchRetryInterval);
        this.fetchRetryInterval = undefined;
      }
    }, 1000);
  }

  toggleCode(): void {
    if (this.showCode()) {
      return;
    }
    this.showCode.set(true);
    this.showCodeTimeout = setTimeout(() => {
      this.showCode.set(false);
    }, 20000);
  }

  confirmEmailResend() {
  //   const confirmation = this.dialog.open(CBoxProfilePickUpRequestEmailSendConfirmationDialogComponent, {
  //     width: "min(400px, 100%)",
  //     autoFocus: false
  //   });

  //   confirmation.afterClosed().pipe(first()).subscribe((confirmed) => {
  //     if (confirmed) {
  //       this.resendEmail();
  //     }
  //   });
  }

  confirmSMSResend() {
  //   const confirmation = this.dialog.open(CBoxProfilePickUpRequestSmsSendConfirmationDialogComponent, {
  //     width: "min(400px, 100%)",
  //     autoFocus: false
  //   });

  //   confirmation.afterClosed().pipe(first()).subscribe((confirmed) => {
  //     if (confirmed) {
  //       this.resendSMS();
  //     }
  //   });
  }

  confirmRequestPickUp(event: Event): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      header: "Confirmare",
      message: "Doriți să confirmați ridicarea coletului?",
      acceptLabel: "Confirm ridicarea",
      rejectLabel: "Renunță",
      accept: () => {
        this.performRequestPickUpConfirmation();
      }
    });
  }

  openLockerDetails(): void {
    this.dialog.open(CBoxProfileLockerDataComponent, {
      data: this.orderData()?.locker?.name,
      minWidth: "min(1200px, 100%)"
    });
  }

  getStatus(request: CBox_GetPickUpDataResponse): {
    status: string;
    color: string
  } {
    const requestFlags = request.flags;
    if (!this.requestHasActiveFlags(request)) {
      return { status: "Initial", color: "grey" }; // Default/Neutral color
    }

    const isInLocker = requestFlags.isInLocker;
    const isFinished = requestFlags.isFinished;
    const isPickedUpByUser = requestFlags.isPickedUpByUser;
    const isPickedBackByCourier = requestFlags.isPickedBackByCourier;
    const isCanceled = requestFlags.isCanceled;
    const isExpired = requestFlags.isExpired;
    const isIndeterminate = requestFlags.isIndeterminate;
    if (isInLocker && isPickedUpByUser && isIndeterminate) {
      return { status: "Activitate de preluare din locker", color: "var(--yellow-400)" };
    }

    if (isInLocker && !isFinished && !isPickedUpByUser && !isPickedBackByCourier) {
      let status = "In locker";
      let color = "var(--blue-400)";
      if (isCanceled) {
        status += " (anulată)";
        color = "var(--red-400)";
      }
      if (isExpired) {
        status += " (expirată)";
        color = "var(--yellow-400)";
      }
      return { status, color };
    }

    if (requestFlags.isPickedUpByUser) return { status: "Ridicată de client", color: "var(--green-400)" };
    if (requestFlags.isPickedBackByCourier) return { status: "Recuperată", color: "var(--yellow-400)" };
    if (requestFlags.isCanceled) return { status: "Anulată", color: "var(--red-400)" };
    if (requestFlags.isExpired) return { status: "Expirată", color: "var(--red-400)" };

    return { status: "Status necunoscut", color: "var(--surface-400)" };
  }

  requestHasActiveFlags(request: CBox_GetPickUpDataResponse): boolean {
    const flagValues = Object.values(request.flags);
    return flagValues.some((flag) => flag === true);
  }

  isPastDate(date: string | null | undefined): boolean {
    return new Date(date || "") < new Date();
  }

  private performRequestPickUpConfirmation(): void {
    this.api.patch("backend/request/pick-up?responseId=" + this.orderData()?.responseId, {
      markAsDelivered: true
    }).subscribe(() => {
      this.fetchPickupOrderInfo();
      this.toastService.showSuccessToast("Confirmare", "Ridicarea coletului a fost confirmată cu succes!");
    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
    });
  }

  private resendEmail(): void {
    this.sendingEmail.set(true);
    this.api.patch("backend/request/pick-up?responseId=" + this.orderData()?.responseId, {
      notify: {
        email: {
          resend: true
        }
      }
    }).subscribe(() => {
      this.toastService.showSuccessToast("Confirmare", "Email-ul a fost retrimis cu succes!");
      this.fetchPickupOrderInfo();
      this.sendingEmail.set(false);
    }, (e: HttpErrorResponse) => {
      this.sendingEmail.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }

  private resendSMS(): void {
    this.sendingSMS.set(true);
    this.api.patch("backend/request/pick-up?responseId=" + this.orderData()?.responseId, {
      notify: {
        sms: {
          resend: true
        }
      }
    }).subscribe(() => {
      this.toastService.showSuccessToast("Confirmare", "SMS-ul a fost retrimis cu succes!");
      this.fetchPickupOrderInfo();
      this.sendingSMS.set(false);
    }, (e: HttpErrorResponse) => {
      this.sendingSMS.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }

  updatePhoneNumber(): void {
    // const dialog = this.dialog.open(CBoxProfilePickUpRequestPhoneNumberChangeDialogComponent, {
    //   data: this.orderData()?.responseId,
    //   width: "min(600px, 100%)",
    //   autoFocus: false
    // });

    // dialog.afterClosed().pipe(first()).subscribe((dialogResponse: {
    //   phoneNumberChanged: boolean
    // }) => {
    //   if (dialogResponse.phoneNumberChanged) {
    //     this.fetchPickupOrderInfo();
    //   }
    // });
  }

  updateEmailAddress(): void {
    // const dialog = this.dialog.open(CBoxProfilePickUpRequestEmailAddressChangeDialogComponent, {
    //   data: this.orderData()?.responseId,
    //   width: "min(600px, 100%)",
    //   autoFocus: false
    // });

    // dialog.afterClosed().pipe(first()).subscribe((dialogResponse: {
    //   emailAddressChanged: boolean
    // }) => {
    //   if (dialogResponse.emailAddressChanged) {
    //     this.fetchPickupOrderInfo();
    //   }
    // });
  }

  private async init(): Promise<void> {
    this.fetchPickupOrderInfo();
  }
}
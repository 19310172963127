import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, Inject, InjectionToken, Injector, OnInit, PLATFORM_ID, computed, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CBox_PublicErrorResponse, CBox_PublicPaginatedResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { CBox_GetApiUserListRequestParams, CBox_GetApiUserListResponseItem } from '@server/services/cbox/public/api/v1/resources/user/types';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule, TablePageEvent } from 'primeng/table';
import { debounceTime, first } from 'rxjs';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { ProfileService } from 'src/services/profile/profile.service';
import { SessionService } from 'src/services/session/session.service';
import { CBoxProfileApiUserCreateComponent } from './dialogs/create/cbox-profile-api-user-create.component';
import { CBoxProfileApiUserEditComponent } from './dialogs/edit/cbox-profile-api-user-edit.component';
import { CBoxProfileApiUserRegenerateSecretDialogComponent } from './dialogs/regenerate-secret/cbox-profile-api-user-regenerate-secret-dialog.component';
import { CBoxProfileApiUserDeleteDialogComponent } from './dialogs/delete/cbox-profile-api-user-delete-dialog.component';
import { getLastActiveMessage } from 'src/helpers/functions';
import { ToastService } from 'src/services/toast/toast.service';
import { PermissionDirective } from 'src/directives/permission.directive';
import { PermissionNamesEnum } from '@server/services/cbox/public/api/v1/enforcers/entity_permission/types';
import { ConfigurationService } from 'src/services/configuration/configuration.service';
import { TableWithFilters } from 'app/shared/table-with-filters';

@Component({
  selector: 'app-cbox-profile-api-users-list',
  templateUrl: './cbox-profile-api-users-list.component.html',
  styleUrls: ["./cbox-profile-api-users-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    ReactiveFormsModule,
    MenuModule,
    ProgressBarModule,
    InputTextModule,
    PermissionDirective
  ]
})

export class CBoxProfileApiUsersListComponent extends TableWithFilters<CBox_GetApiUserListResponseItem, CBox_GetApiUserListRequestParams> implements OnInit {
  filtersForm: FormGroup;
  dataFetchUrl = "users/api";
  sessionFiltersKey = "profile/users/api/filters";
  sessionFiltersToSave = ["page"];
  localStorageFiltersKey = "profile/users/api/filters";
  localStorageFiltersToSave = ["pageSize"];
  defaultFilters = {
    pageSize: 25,
    page: 1
  };
  permissions = PermissionNamesEnum;

  constructor(
    inj: Injector,
    private profileService: ProfileService,
    private fb: FormBuilder,
    private session: SessionService,
    private dialog: MatDialog,
    private destroyRef: DestroyRef) {
      super(inj);
      this.filtersForm = this.fb.group({
        page: [1],
        pageSize: [25]
      });
    }

  ngOnInit(): void {
    this.baseInit();
  }

  async init(): Promise<void> {
    this.profileService.setTitle("Listă utilizatori");
  }

  addUser(): void {
    const dialog = this.dialog.open(CBoxProfileApiUserCreateComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe((created: boolean) => {
      if (created) {
        this.toastService.showSuccessToast("Confirmare", "Utilizatorul a fost adăugat cu succes!");
        this.loading.set(true);
        this.fetchData();
      }
    });
  }

  editApiUser(apiUser: CBox_GetApiUserListResponseItem | undefined): void {
    if (!apiUser) {
      return;
    }
    const dialog = this.dialog.open(CBoxProfileApiUserEditComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false,
      data: apiUser.identifier
    });

    dialog.afterClosed().pipe(first()).subscribe((edited: boolean) => {
      if (edited) {
        this.toastService.showSuccessToast("Confirmare", "Utilizatorul a fost editat cu succes!");
        this.loading.set(true);
        this.fetchData();
      }
    });
  }

  regenerateApiUserCredentials(apiUser: CBox_GetApiUserListResponseItem | undefined): void {
    if (!apiUser) {
      return;
    }
    const dialog = this.dialog.open(CBoxProfileApiUserRegenerateSecretDialogComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false,
      data: apiUser
    });

    dialog.afterClosed().pipe(first()).subscribe((regenerated: boolean) => {
      if (regenerated) {
        this.loading.set(true);
        this.fetchData();
      }
    });
  }

  deleteApiUser(apiUser: any): void {
    if (!apiUser) {
      return;
    }
    const dialog = this.dialog.open(CBoxProfileApiUserDeleteDialogComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false,
      data: apiUser
    });

    dialog.afterClosed().pipe(first()).subscribe((deleted: boolean) => {
      if (deleted) {
        this.toastService.showSuccessToast("Confirmare", "Utilizatorul a fost șters cu succes!");
        this.loading.set(true);
        this.fetchData();
      }
    });
  }

  getLastActiveMessage(lastUsedAt: string): string {
    return getLastActiveMessage(lastUsedAt);
  }

  getUserMenuItems(user: CBox_GetApiUserListResponseItem): MenuItem[] {
    return [
      {
        label: "Regenerează credențiale",
        icon: "pi pi-pencil",
        visible: this.configurationService.hasAccess([PermissionNamesEnum.regenerateApiUserCredentials]),
        command: () => {
          this.regenerateApiUserCredentials(user);
        }
      },
      {
        label: "Editează utilizator",
        icon: "pi pi-pencil",
        visible: this.configurationService.hasAccess([PermissionNamesEnum.editApiUser]),
        command: () => {
          this.editApiUser(user);
        }
      },
      {
        label: "Sterge utilizator",
        icon: "pi pi-trash",
        visible: this.configurationService.hasAccess([PermissionNamesEnum.deleteApiUser]),
        command: () => {
          this.deleteApiUser(user);
        }
      }
    ];
  }

  getSearchStructure() {
    const data = {
      page: this.filtersForm.value.page,
      pageSize: this.filtersForm.value.pageSize,
    };
    return Object.entries(data)
    .reduce((acc, [key, value]) => {
      acc[key as any] = value;
      return acc;
    }, {} as any);
  }
}
@if (apiUserCreateStatus() !== "created") {
  <div class="dialog-header flex align-items-center justify-content-between w-full">
    <h2>Adaugă utilizator API </h2>
    <p-button
      size="small"
      mat-dialog-close
      icon="pi pi-times"
      severity="secondary"
      [rounded]="true"
      [text]="true"
    />
  </div>
}
<mat-dialog-content>
  @if (apiUserCreateStatus() === "waiting") {
    <form [formGroup]="apiUserForm" class="grid grid-nogutter gap-2">
      <div class="col-12">
        <span class="block font-bold mb-1">Rol</span>
        <p-dropdown
          optionLabel="roleName"
          optionValue="roleName"
          styleClass="w-full"
          placeholder="Selectati un rol"
          formControlName="role"
          [options]="roles()"
          [loading]="!roles()"
        ></p-dropdown>
      </div>

      <div class="col-12">
        <span class="font-bold block mb-1">Descriere</span>
        <textarea
          class="w-full"
          rows="5"
          cols="30"
          pInputTextarea
          formControlName="description"
        ></textarea>
      </div>
    </form>
  } @else if (apiUserCreateStatus() === "created") {
    <div class="flex flex-column align-items-center justify-content-center text-center">
      <span class="material-symbols-rounded md-64 text-green-500">task_alt</span>
      <h2 class="text-center text-green-500 line-height-2 mb-0">Utilizatorul a fost creat cu succes!</h2>
      <p class="text-2xl font-bold">Credentiale utilizator</p>
      <p class="text-lg mb-0 word-wrap-anywhere max-w-full line-height-2">
        <span class="font-bold">Client ID:</span>
        {{ apiUserCreateResponse()?.clientId }}
      </p>
      <p class="text-lg mb-0 word-wrap-anywhere max-w-full line-height-2">
        <span class="font-bold">Client secret:</span>
        {{ apiUserCreateResponse()?.clientSecret }}
      </p>
      <p-button
        size="small"
        [severity]="clientSecretCopied() ? 'success' : 'primary'"
        [label]="clientSecretCopied() ? 'Parola a fost copiată' : 'Copiază parolă'"
        [icon]="clientSecretCopied() ? 'pi pi-check' : 'pi pi-copy'"
        iconPos="right"
        [text]="true"
        (click)="copyClientSecret()"
      ></p-button>
      <p>Atenție! Parola generată nu va mai putea fi vizualizată, ci doar resetată.</p>
      <div class="inline-flex align-items-center gap-2 mb-2">
        <p-checkbox
          inputId="confirmation"
          [formControl]="confirmation"
          [binary]="true"
        ></p-checkbox>
        <label
          for="confirmation"
          [ngClass]="{
            'text-red-500': !confirmation.value && confirmation.dirty && confirmation.touched
          }"
        >Confirm că am copiat parola</label>
      </div>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions>
  @if (apiUserCreateStatus() !== "created") {
    <button
      pButton
      class="w-full"
      label="Adaugă"
      [disabled]="!apiUserForm.valid"
      (click)="createUser()"
    ></button>
  } @else {
    <button
      pButton
      class="w-full"
      label="Închide"
      [disabled]="confirmation.invalid"
      [mat-dialog-close]="true"
    ></button>
  }
</mat-dialog-actions>
@if (apiUserCreateStatus() === "creating") {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}


import { CommonModule } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_AdminActionUpdateTodoRequestData, CBox_AdminGetTodoListDataItem } from "@server/services/cbox/public/api/v1/resources/internal/todo/types";
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MultiSelectModule } from "primeng/multiselect";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { markFormDirty } from "src/helpers/functions";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ToastService } from "src/services/toast/toast.service";

type TranslatedState = {
  value: string;
  label: string;
}

@Component({
  selector: "app-cbox-to-do-update-dialog",
  templateUrl: "./cbox-to-do-update-dialog.component.html",
  styleUrls: ["./cbox-to-do-update-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogClose,
    MatDialogContent,
    MatDialogActions,
    ButtonModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextareaModule
  ]
})

export class CBoxToDoUpdateDialogComponent implements OnInit {

  form: FormGroup;
  states = signal<TranslatedState[]>([
    {
      value: "DONE",
      label: "Finalizat"
    },
    {
      value: "INITIAL",
      label: "În lucru"
    },
    {
      value: "NOT_STARTED",
      label: "Neînceput"
    }
  ]);
  loading = signal(false);

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxToDoUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private toDo: CBox_AdminGetTodoListDataItem) {
      console.log(toDo, "TO DO");
    this.form = this.fb.group({
      text: [""],
      state: [undefined]
    });

    this.form.patchValue({
      state: toDo.state,
      text: this.getLastText()
    });
  }

  ngOnInit() {}

  fetchToDo(): void {
    this.api.get("backend/internal/todo")
  }

  update(): void {
    if (this.form.invalid) {
      markFormDirty(this.form);
      return;
    }

    this.loading.set(true);
    this.api.patch("backend/internal/todo/" + this.toDo.id, this.getUpdateStructure()).subscribe(() => {
      this.toastService.showSuccessToast("Confirmare", "To do-ul a fost actualizat cu succes");
      this.dialogRef.close(true);
      this.loading.set(false);
    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
      this.loading.set(false);
    });
  }

  getLastText(): string {
    const last = this.toDo.history.length - 1;
    return this.toDo.history[last].text;
  }

  private getUpdateStructure(): CBox_AdminActionUpdateTodoRequestData["body"] {
    return {
      state: this.form.value.state,
      text: this.form.value.text
    }
  }
}
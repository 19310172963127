import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, InjectionToken, input, OnInit, output, PLATFORM_ID, signal } from '@angular/core';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { CBox_AdminGetTodoListDataItem, CBox_AdminGetTodoListDataParams, CBox_AdminTodoState } from "@server/services/cbox/public/api/v1/resources/internal/todo/types";
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AccordionModule } from 'primeng/accordion';
import { TableModule } from 'primeng/table';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { ToastService } from 'src/services/toast/toast.service';
import { MatDialog } from '@angular/material/dialog';
import { CBoxToDoCreateDialogComponent } from '../create/cbox-to-do-create-dialog.component';
import { first } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { CBoxToDoUpdateDialogComponent } from '../update/cbox-to-do-update-dialog.component';

@Component({
  selector: 'app-cbox-to-do-list',
  templateUrl: './cbox-to-do-list.component.html',
  styleUrls: ["./cbox-to-do-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    TriStateCheckboxModule,
    ReactiveFormsModule,
    AccordionModule,
    TableModule,
    MenuModule,
    ButtonModule
  ]
})

export class CBoxToDoListComponent implements OnInit {
  lockerIdentifiers = input<string[]>([]);
  equipmentIds = input<number[]>([]);
  states = input<CBox_AdminTodoState[]>([]);
  onUpdate = output<void>();

  todos = signal<CBox_AdminGetTodoListDataItem[]>([]);
  isAllSelected: boolean | null = null;
  form = new FormGroup({
    isAllSelected: new FormControl<boolean | null>(null)
  });

  isLoading = signal<Record<number, true>>({});
  expandedRows = signal<Record<number, true>>({});

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {}

  ngOnInit(): void {
    this.fetchData();
  }

  getToDoMenuItems(task: CBox_AdminGetTodoListDataItem,todo: CBox_AdminGetTodoListDataItem["history"][0]): MenuItem[] {
    return [
      {
        label: "Marcheaza ca finalizat",
        command: () => {
          this.markAsDone(task, todo);
        }
      }
    ];
  }

  createToDo(): void {
    const dialog = this.dialog.open(CBoxToDoCreateDialogComponent, {
      data: {
        lockerIdentifiers: this.lockerIdentifiers()
      },
      width: "min(600px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(async () => {
      this.fetchData();
      this.onUpdate.emit();
    });
  }

  updateToDo(todo: CBox_AdminGetTodoListDataItem): void {
    const dialog = this.dialog.open(CBoxToDoUpdateDialogComponent, {
      data: todo,
      width: "min(600px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(async () => {
      this.fetchData();
      this.onUpdate.emit();
    });
  }

  private markAsDone(task: CBox_AdminGetTodoListDataItem, todo: CBox_AdminGetTodoListDataItem["history"][0]): void {
    this.isLoading.update(data => {
      data[task.id] = true;
      return data;
    });
    this.api.patch("backend/internal/todo/" + task.id, {
      state: CBox_AdminTodoState.DONE,
    }).subscribe(() => {
      this.isLoading.update(data => {
        delete data[task.id];
        return data;
      });
    }, (e: HttpErrorResponse) => {
      this.isLoading.update(data => {
        delete data[task.id];
        return data;
      });

      handlePublicApiError(e, this.toastService);
    })
  }

  private fetchData(): void {
    if (isPlatformBrowser(this.platformId)) {
      const params = new HttpParams({
        fromObject: this.getSearchStructure()
      });
      this.api.get<CBox_PublicSuccessResponse<CBox_AdminGetTodoListDataItem[]>>("backend/internal/todo", params).subscribe((response) => {
        console.log(response, "TO DO LIST RESPONSE");
        this.todos.set(response.data);
        const isAllSelected = this.todos().every(todo => todo.state === "DONE");;
        const isAnySelected = this.todos().some(todo => todo.state === "DONE");
        this.isAllSelected = isAnySelected ? isAllSelected ? true : null : false;
      });
    }
  }

  private getSearchStructure(): CBox_AdminGetTodoListDataParams {
    return Object.entries({
      "lockerIdentifiers[]": this.lockerIdentifiers(),
      "equipmentIds[]": this.equipmentIds(),
      "states[]": this.states(),
      page: 1,
      pageSize: 25
    }).filter(([_, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }

      return value !== null && value !== undefined;
    })
    .reduce((acc, [key, value]) => {
      acc[key as keyof CBox_AdminGetTodoListDataParams] = value as CBox_AdminGetTodoListDataParams[keyof CBox_AdminGetTodoListDataParams];
      return acc;
    }, {} as any);
  }

  getFirstToDo(task: CBox_AdminGetTodoListDataItem): CBox_AdminGetTodoListDataItem["history"][0] {
    return task.history[0];
  }

  getLastToDo(task: CBox_AdminGetTodoListDataItem): CBox_AdminGetTodoListDataItem["history"][0] {
    return task.history[task.history.length - 1];
  }

  toggleRow(task: CBox_AdminGetTodoListDataItem): void {
    if (this.expandedRows()[task.id]) {
      this.expandedRows.update(data => {
        delete data[task.id];
        return data;
      });
    } else {
      this.expandedRows.update(data => {
        data[task.id] = true;
        return data;
      });
    }
  }

  isRowExpanded(task: CBox_AdminGetTodoListDataItem): boolean {
    return !!this.expandedRows()[task.id];
  }
}
import { CommonModule } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_AdminEquipmentListItem } from "@server/services/cbox/public/api/v1/resources/internal/equipment/types";
import { CBox_AdminActionCreateTodoRequestData } from "@server/services/cbox/public/api/v1/resources/internal/todo/types";
import { CBox_GetLockerShortData } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MultiSelectModule } from "primeng/multiselect";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { markFormDirty } from "src/helpers/functions";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { LockersService } from "src/services/lockers/lockers.service";
import { ToastService } from "src/services/toast/toast.service";

@Component({
  selector: "app-cbox-to-do-create-dialog",
  templateUrl: "./cbox-to-do-create-dialog.component.html",
  styleUrls: ["./cbox-to-do-create-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    MatDialogClose,
    MatDialogContent,
    MatDialogActions,
    MultiSelectModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    InputTextareaModule
  ]
})

export class CBoxToDoCreateDialogComponent implements OnInit {

  form: FormGroup;
  lockers = signal<CBox_GetLockerShortData[]>([]);
  equipments = signal<CBox_AdminEquipmentListItem[]>([]);
  loading = signal(false);
  loadingEquipments = signal(false);

  constructor(
    private api: ApiService,
    private lockersService: LockersService,
    private dialogRef: MatDialogRef<CBoxToDoCreateDialogComponent>,
    private toastService: ToastService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      lockerIdentifiers: string;
      equipment: {
        id: number;
        typeName: string;
      }
    }) {
    this.form = this.fb.group({
      lockerIdentifiers: [[]],
      text: ["", Validators.required]
    });

    this.form.patchValue(this.data);
  }

  ngOnInit(): void {
    this.init();
  }

  createEquipment(): void {
    this.loading.set(true);
    this.api.post("backend/internal/todo", this.getCreateStructure()).subscribe(() => {
      this.toastService.showSuccessToast("Confirmare", "To do-ul a fost adăugat cu succes");
      this.loading.set(false);
      this.dialogRef.close();
    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
      this.loading.set(false);
    });
  }

  create(): void {
    if (this.form.invalid) {
      markFormDirty(this.form);
    }
    this.loading.set(true);
    this.api.post("backend/internal/todo", this.getCreateStructure()).subscribe(() => {
      this.toastService.showSuccessToast("Confirmare", "To do-ul a fost adăugat cu succes");
      this.loading.set(false);
      this.dialogRef.close(true);
    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
      this.loading.set(false);
    });
  }

  lockerSelected(): void {
    this.loadingEquipments.set(true);
    this.api.get("backend/internal/modules")
  }

  private async init(): Promise<void> {
    if (this.data.lockerIdentifiers) {
      this.lockers.set(await this.lockersService.getList());
      this.form.get("lockerIdentifiers")?.setValidators([Validators.required]);
    }
    this.loading.set(false);
  }

  private getCreateStructure(): CBox_AdminActionCreateTodoRequestData {
    const f = this.form.value;
    const data: CBox_AdminActionCreateTodoRequestData = {
      text: f.text,
      links: []
    };

    if (f.lockerIdentifiers?.length) {
      data.links = f.lockerIdentifiers.map((lockerIdentifier: string) => ({
        lockerIdentifier
      }));
    }

    if (this.data.equipment) {
      data.links = [
        {
          equipmentId: this.data.equipment.id
        }
      ]
    }

    return data;
  }
}
<p-button
  *appRights="[permissions.createPlatformUser]"
  label="Adaugă utilizator"
  icon="pi pi-plus"
  class="mb-2"
  (click)="createUser()"
></p-button>

<form [formGroup]="filtersForm" class="grid align-items-end row-gap-1 mt-2">
  <div class="col-12 md:col-4">
    <span class="font-bold block mb-1">Nume</span>
    <p-iconField class="w-full" iconPosition="right">
      @if (filtersForm.get('name')?.value?.length > 0) {
        <p-inputIcon
          styleClass="pi pi-times cursor-pointer"
          (click)="filtersForm.patchValue({ name: '' })"
        />
      }
      <input
        pInputText
        class="w-full"
        placeholder="Nume"
        formControlName="name"
      />
    </p-iconField>
  </div>

  <div class="col-12 md:col-4">
    <span class="font-bold block mb-1">Email</span>
    <p-iconField class="w-full" iconPosition="right">
      @if (filtersForm.get('email')?.value?.length > 0) {
        <p-inputIcon
          styleClass="pi pi-times cursor-pointer"
          (click)="filtersForm.patchValue({ email: '' })"
        />
      }
      <input
        pInputText
        class="w-full"
        placeholder="Email"
        formControlName="email"
      />
    </p-iconField>
  </div>

  <div class="col-12 md:col-4">
    <span class="font-bold block mb-1">Telefon</span>
    <p-iconField class="w-full" iconPosition="right">
      @if (filtersForm.get('phone')?.value?.length > 0) {
        <p-inputIcon
          styleClass="pi pi-times cursor-pointer"
          (click)="filtersForm.patchValue({ phone: '' })"
        />
      }
      <input
        pInputText
        class="w-full"
        placeholder="Telefon"
        formControlName="phone"
      />
    </p-iconField>
  </div>
</form>

<p-table
  styleClass="p-datatable-sm"
  currentPageReportTemplate="{first} - {last} din {totalRecords} utilizatori"
  paginatorPosition="both"
  [totalRecords]="totalCount()"
  [paginator]="true"
  [lazy]="true"
  [rows]="filtersForm.get('pageSize')?.value"
  [value]="data()"
  [rowsPerPageOptions]="[25, 50, 100]"
  [showCurrentPageReport]="true"
  (onPage)="pageChanged($event)"
>
  <ng-template pTemplate="header">
    @if (loading()) {
      <tr>
        <td colspan="4">
          <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" />
        </td>
      </tr>
    }
    <tr>
      <th>Nume</th>
      <th>Email</th>
      <th>Zonă</th>
      <th>Rol</th>
      <th *appRights="[
        permissions.resetPlatformUserPassword, permissions.editPlatformUser, permissions.deletePlatformUser
      ]"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user>
    <tr>
      <td>
        <p class="text-xs text-gray-400 m-0">{{ user.identifier }}</p>
        <b class="text-lg">{{ user.name }}</b>
      </td>
      <td>{{ user.email }}</td>
      <td>
        {{ getZoneName(user.zoneId) }}
      </td>
      <td>{{ user.role }}</td>
      <td *appRights="[
        permissions.resetPlatformUserPassword, permissions.editPlatformUser, permissions.deletePlatformUser
      ]" class="text-right p-0">
        <p-menu
          #userMenu
          styleClass="w-15rem"
          appendTo="body"
          [tabindex]="undefined"
          [popup]="true"
          [model]="getUserMenuItems(user)"
        />
        @if (currentUser()?.email !== user.email) {
          <button
            pButton
            size="small"
            icon="pi pi-ellipsis-v"
            [plain]="true"
            [text]="true"
            [rounded]="true"
            (click)="userMenu.toggle($event)"
          ></button>
        }
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
      @if (!loading()) {
      <tr>
        <td colspan="4">
          <p class="text-center text-gray-400 text-xl font-bold">Nu există utilizatori</p>
        </td>
      </tr>
    }
  </ng-template>
</p-table>

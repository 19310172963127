import { CommonModule, isPlatformBrowser } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostListener, Inject, InjectionToken, OnInit, PLATFORM_ID } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { RouterOutlet } from "@angular/router";
import { CBox_PublicGetReportListItemData, CBox_PublicReportFileTypes } from "@server/services/cbox/public/api/v1/resources/reports/types";
import { PrimeNGConfig } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ToastModule } from "primeng/toast";
import { ReportsService } from "src/services/reports/reports.service";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    ToastModule,
    ButtonModule,
    ProgressSpinnerModule
  ],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {

  reportFileTypes = CBox_PublicReportFileTypes;

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    // private toastService: ToastService,
    public reportsService: ReportsService,
    private primeng: PrimeNGConfig,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>
  ) {
    this.configPrimeNG();
    iconRegistry.setDefaultFontSetClass("material-symbols-rounded");
    iconRegistry.addSvgIcon(
      "logo",
      sanitizer.bypassSecurityTrustResourceUrl("assets/logo-black.svg")
    );

    iconRegistry.addSvgIcon(
      "logo-small",
      sanitizer.bypassSecurityTrustResourceUrl("assets/logo-small.svg")
    );

    iconRegistry.addSvgIcon(
      "cbox-by-logo",
      sanitizer.bypassSecurityTrustResourceUrl("assets/cbox-by-logo.svg")
    );

    iconRegistry.addSvgIcon(
      "ro-post-logo",
      sanitizer.bypassSecurityTrustResourceUrl("assets/ro-post-logo.svg")
    );
  }

  downloadReport(report: CBox_PublicGetReportListItemData, fileType: CBox_PublicReportFileTypes): void {
    this.reportsService.downloadReport(report, fileType);
  }

  reportHasFileType(report: CBox_PublicGetReportListItemData, fileType: CBox_PublicReportFileTypes): boolean {
    return report.files.some((file) => file.type === fileType);
  }

  configPrimeNG(): void {
    this.primeng.ripple = true;
    this.primeng.setTranslation({
      startsWith: "Începe cu",
      contains: "Conține",
      notContains: "Nu conține",
      endsWith: "Se termină cu",
      equals: "Egal cu",
      notEquals: "Nu este egal cu",
      noFilter: "Fără filtru",
      lt: "Mai mic decât",
      lte: "Mai mic sau egal cu",
      gt: "Mai mare decât",
      gte: "Mai mare sau egal cu",
      dateIs: "Data este",
      dateIsNot: "Data nu este",
      dateBefore: "Data este înainte",
      dateAfter: "Data este după",
      clear: "Șterge",
      apply: "Aplică",
      matchAll: "Potrivire completă",
      matchAny: "Potrivire oricare",
      addRule: "Adaugă regulă",
      removeRule: "Șterge regulă",
      accept: "Da",
      reject: "Nu",
      choose: "Alege",
      upload: "Încarcă",
      cancel: "Anulează",
      pending: "În așteptare",
      dayNames: ["Duminică", "Luni", "Marți", "Miercuri", "Joi", "Vineri", "Sâmbătă"],
      dayNamesShort: ["Dum", "Lun", "Mar", "Mie", "Joi", "Vin", "Sâm"],
      dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: [
        "Ianuarie",
        "Februarie",
        "Martie",
        "Aprilie",
        "Mai",
        "Iunie",
        "Iulie",
        "August",
        "Septembrie",
        "Octombrie",
        "Noiembrie",
        "Decembrie"
      ],
      monthNamesShort: [
        "Ian",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Iun",
        "Iul",
        "Aug",
        "Sep",
        "Oct",
        "Noi",
        "Dec"
      ],
      chooseYear: "Alege anul",
      chooseMonth: "Alege luna",
      chooseDate: "Alege data",
      prevDecade: "Deceniul anterior",
      nextDecade: "Deceniul următor",
      prevYear: "Anul anterior",
      nextYear: "Anul următor",
      prevMonth: "Luna anterioară",
      nextMonth: "Luna următoare",
      prevHour: "Ora anterioară",
      nextHour: "Ora următoare",
      prevMinute: "Minutul anterior",
      nextMinute: "Minutul următor",
      prevSecond: "Secunda anterioară",
      nextSecond: "Secunda următoare",
      am: "AM",
      pm: "PM",
      today: "Astăzi",
      weekHeader: "Săptămâna",
      firstDayOfWeek: 1,
      dateFormat: "dd/mm/yy",
      weak: "Slab",
      medium: "Mediu",
      strong: "Puternic",
      passwordPrompt: "Introduceți o parolă",
      emptyFilterMessage: "Niciun rezultat găsit",
      searchMessage: "{0} rezultate găsite",
      selectionMessage: "{0} elemente selectate",
      emptySelectionMessage: "Niciun element selectat",
      emptySearchMessage: "Niciun rezultat găsit",
      emptyMessage: "Niciun rezultat găsit",
      aria: {
        trueLabel: "Adevărat",
        falseLabel: "Fals",
        nullLabel: "Null",
        star: "Stea",
        stars: "Stele",
        selectAll: "Selectează tot",
        unselectAll: "Deselectează tot",
        close: "Închide",
        previous: "Anterior",
        next: "Următor",
        navigation: "Navigare",
        scrollTop: "Derulează în sus",
        moveTop: "Mutați în partea de sus",
        moveUp: "Mutați mai sus",
        moveDown: "Mutați mai jos",
        moveBottom: "Mutați în partea de jos",
        moveToTarget: "Mutați la țintă",
        moveToSource: "Mutați la sursă",
        moveAllToTarget: "Mutați toate la țintă",
        moveAllToSource: "Mutați toate la sursă",
        pageLabel: "Pagina",
        firstPageLabel: "Prima pagină",
        lastPageLabel: "Ultima pagină",
        nextPageLabel: "Pagina următoare",
        prevPageLabel: "Pagina anterioară",
        rowsPerPageLabel: "Elemente pe pagină",
        jumpToPageDropdownLabel: "Sari la pagină",
        jumpToPageInputLabel: "Introduceți numărul paginii",
        selectRow: "Selectați rândul",
        unselectRow: "Deselectați rândul",
        expandRow: "Extindeți rândul",
        collapseRow: "Restrângeți rândul",
        showFilterMenu: "Arate meniul de filtrare",
        hideFilterMenu: "Ascundeți meniul de filtrare",
        filterOperator: "Operator de filtrare",
        filterConstraint: "Constrângere de filtrare",
        editRow: "Editați rândul",
        saveEdit: "Salvați",
        cancelEdit: "Anulați",
        listView: "Vizualizare listă",
        gridView: "Vizualizare grilă",
        slide: "Glisați",
        slideNumber: "Numărul {0}",
        zoomImage: "Măriți imaginea",
        zoomIn: "Măriți",
        zoomOut: "Micșorați",
        rotateRight: "Rotiți la dreapta",
        rotateLeft: "Rotiți la stânga",
      }
    })
  }

}


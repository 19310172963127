<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Adaugă utilizator</h2>
  <p-button
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  @if (!isCreating()) {
    <form [formGroup]="userForm" class="grid">
      @if (!userForm.get("customIdentifier")?.value) {
        <p-messages
          styleClass="text-xs"
          class="col-12"
          [value]="autoGeneratedIdentifierMessage()"
          [enableService]="false"
          [closable]="false" />
      }
      <div class="col-12 sm:col-6">
        <span class="block font-bold mb-1">Nume</span>
        <input
          autocomplete="off"
          class="w-full"
          type="text"
          pInputText
          placeholder="Nume"
          pAutoFocus
          [autofocus]="true"
          formControlName="name"
        >
      </div>

      <div class="col-12 sm:col-6">
        <span class="font-bold block mb-1">Identificator</span>
        @let identifier = userForm.get("identifier");
        <input
          formControlName="identifier"
          class="w-full"
          id="awb"
          [placeholder]="identifier?.disabled ? 'Se va genera automat' : 'Introduceți identificatorul'"
          pInputText
        >
        @let identifierErrors = userForm.get("identifier")?.errors;
        @if (identifier?.dirty) {
          @if (identifierErrors) {
            @if (identifierErrors?.["required"]) {
              <small class="text-red-500">Identificatorul este obligatoriu!</small>
            }
          }
        }
        <div class="flex align-items-center gap-2">
          <p-checkbox
            class="mt-1"
            [binary]="true"
            formControlName="customIdentifier"
            inputId="customIdentifier"
            (onChange)="toggleCustomIdentifier(); identifier?.reset();"
          ></p-checkbox>
          <label class="-mb-1 text-xs" for="customIdentifier">Identificator personalizat</label>
        </div>
      </div>

      <div class="col-12 sm:col-6">
        <span class="block font-bold mb-1">Email</span>
        <input
          autocomplete="off"
          class="w-full"
          type="text"
          pInputText
          placeholder="Email"
          formControlName="email"
        >
      </div>

      <div class="col-12 sm:col-6">
        <span class="block font-bold mb-1">Telefon</span>
        <input
          autocomplete="off"
          class="w-full"
          type="text"
          pInputText
          placeholder="07xxxxxxxx"
          formControlName="phone"
        >
      </div>

      <div class="col-12">
        <span class="block font-bold mb-1">Rol</span>
        <p-dropdown
          optionLabel="roleName"
          optionValue="roleName"
          styleClass="w-full"
          placeholder="Selectati un rol"
          formControlName="role"
          [options]="roles()"
          [loading]="!roles()"
        ></p-dropdown>
      </div>

      <div class="col-12">
        <span class="font-bold block mb-1">Zonă</span>
        <p-dropdown
          formControlName="zoneId"
          optionLabel="name"
          optionValue="id"
          placeholder="Selectează o zonă"
          styleClass="w-full"
          appendTo="body"
          [options]="zones()"
          [loading]="!zonesFetched()"
        />
        @let zone = userForm.get("zoneId");
        @let zoneErrors = zone?.errors;
        @if (zone?.dirty && zone?.touched) {
          @if (zoneErrors?.["minlength"]) {
            <small class="text-red-500">Vă rugăm să selectați zona de acces</small>
          }
        }
      </div>

      <p-messages
        styleClass="text-xs m-0"
        class="col-12"
        severity="info"
      >
          <ng-template pTemplate>
            <div class="flex align-items-center gap-2">
              <div class="flex-column">
                <div>În cazul în care doriți ca acest utilizator să aibă și acces fizic la lockere, bifați căsuța de mai jos.</div>
                <div class="flex align-items-center gap-2">
                  <p-checkbox class="mt-1" [binary]="true" formControlName="shouldCreateAgent"
                    inputId="shouldCreateAgent"></p-checkbox>
                  <label class="-mb-1 text-xs" for="shouldCreateAgent">Utilizatorul este si agent</label>
                </div>
              </div>
            </div>
          </ng-template>
      </p-messages>

      <div class="col-12">
        <span class="font-bold block mb-1">Notițe</span>
        <textarea
          class="w-full"
          rows="5"
          cols="30"
          pInputTextarea
          formControlName="notes"
        ></textarea>
      </div>
    </form>
  } @else {
    <div class="grid">
      <div class="col-12 py-4">
        <div class="flex align-items-center my-2">
          @if (userCreateStatus() === "unknown") {
            <p-progressSpinner styleClass="w-2rem h-2rem"></p-progressSpinner>
            <p class="m-0 ml-2 text-lg">Se creează utilizatorul...</p>
          }
          @else if (userCreateStatus() === "success") {
            <span class="pi pi-check-circle text-green-500 text-lg"></span>
            <p class="m-0 ml-2 text-lg">Utilizatorul a fost creat cu succes!</p>
          } @else if (userCreateStatus() === "error") {
              <span class="pi pi-times-circle text-red-500 text-lg"></span>
              <p class="m-0 ml-2 text-lg">Eroare la crearea utilizatorului</p>
            @if (userSaveRetryAllowed()) {
              <p-button
                size="small"
                styleClass="px-2 py-1 text-xs ml-3"
                label="Reîncearcă"
                [link]="true"
                (click)="retryUserSave()"
              ></p-button>
            }
          }
        </div>

        @if (userSaveRetryAllowed() && !!this.userForm.get("shouldCreateAgent")?.value) {
          @if (userCreateStatus() === "success") {
            <div class="flex align-items-center my-2">
              @if (agentCreateStatus() === "unknown") {
                <p-progressSpinner styleClass="w-2rem h-2rem"></p-progressSpinner>
                <p class="m-0 ml-2 text-lg">Se creează agentul...</p>
              }
              @else if (agentCreateStatus() === "success") {
                <span class="pi pi-check-circle text-green-500 text-lg"></span>
                <p class="m-0 ml-2 text-lg">Agentul a fost creat cu succes!</p>
              } @else if (agentCreateStatus() === "error") {
                  <span class="pi pi-times-circle text-red-500 text-lg"></span>
                  <p class="m-0 ml-2 text-lg">Eroare la crearea agentului</p>
                @if (agentSaveRetryAllowed()) {
                  <p-button
                    size="small"
                    styleClass="px-2 py-1 text-xs ml-3"
                    label="Reîncearcă"
                    [link]="true"
                    (click)="retryUserSave()"
                  ></p-button>
                }
              }
            </div>
          }
        }
      </div>
    </div>
  }
</mat-dialog-content>
  <mat-dialog-actions align="end">
    @if (!isCreating()) {
      <button
        pButton
        size="small"
        label="Salvează"
        [disabled]="!userForm.valid"
        (click)="saveUser()"
      ></button>
    }
  </mat-dialog-actions>

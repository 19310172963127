import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { CBox_ActionUpdatePickUpParams } from '@server/services/cbox/public/api/v1/resources/pick_up/types';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { ToastService } from 'src/services/toast/toast.service';

@Component({
  selector: 'app-cbox-profile-pick-up-request-phone-number-change-dialog',
  templateUrl: './cbox-profile-pick-up-request-phone-number-change-dialog.component.html',
  styleUrls: ["./cbox-profile-pick-up-request-phone-number-change-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    InputTextModule,
    IconFieldModule,
    InputIconModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    AutoFocusModule
  ]
})

export class CBoxProfilePickUpRequestPhoneNumberChangeDialogComponent implements OnInit {

  phoneNumber = new FormControl("", Validators.required);
  changingPhone = signal(false);

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxProfilePickUpRequestPhoneNumberChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private responseId: string) { }

  ngOnInit() { }

  changePhoneNumber(): void {
    this.changingPhone.set(true);
    this.api.patch("backend/request/pick-up?responseId=" + this.responseId, this.getPhoneNumberChangeStructure())
      .subscribe(() => {
        this.dialogRef.close({
          phoneNumberChanged: true
        });
        this.changingPhone.set(false);
      }, (e: HttpErrorResponse) => {
        this.changingPhone.set(false);
        handlePublicApiError(e, this.toastService);
      });
  }

  private getPhoneNumberChangeStructure(): CBox_ActionUpdatePickUpParams["body"] {
    const data: CBox_ActionUpdatePickUpParams["body"] = {
      notify: {
        sms: {
          resend: false,
          change: {
            phone: this.phoneNumber?.value as string
          }
        }
      }
    };

    return data;
  }
}
import { Routes } from "@angular/router";
import { PermissionGuardService } from "src/guards/permission.guard";
import { LoginComponent } from "./login/login.component";
import { CBoxProfileMeGeneralComponent } from "./profile/user/me/general/cbox-profile-me-general.component";
import { CBoxProfileMeSecurityComponent } from "./profile/user/me/security/cbox-profile-me-security.component";
import { CBoxProfileApiUsersListComponent } from "./profile/user/list/api/cbox-profile-api-users-list.component";
import { CBoxProfilePlatformUsersListComponent } from "./profile/user/list/platform/cbox-profile-platform-users-list.component";
import { CBoxProfileAgentsReportsListComponent } from "./profile/reports/agents/cbox-profile-agents-reports-list.component";
import { ConfigurationGuard } from "src/guards/logged.guard";
import { CBoxAdminEquipmentsListComponent } from "./profile/admin/components/list/cbox-admin-equipments-list.component";
import { CBoxAdminLockersListComponent } from "./profile/admin/lockers/list/cbox-admin-lockers-list.component";
import { CBoxAdminLockerModulesListComponent } from "./profile/admin/modules/list/cbox-admin-locker-modules-list.component";
import { PermissionNamesEnum } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { CBoxProfileAdminLockerProceduresListComponent } from "./profile/admin/procedures/cbox-profile-admin-locker-procedures-list.component";
import { CBoxProfileLockersReportsListComponent } from "./profile/reports/lockers/cbox-profile-lockers-reports-list.component";
import { CBoxProfileGeneratedReportsComponent } from "./profile/reports/generated/cbox-profile-generated-reports.component";

export const routes: Routes = [
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "profile",
    loadComponent: () => import('./profile/cbox-profile-container.component')
                          .then(m => m.ProfileContainerComponent),
    canActivate: [ConfigurationGuard],
    loadChildren: () => [
      {
        path: "lockers/list",
        loadComponent: () => import('./profile/lockers/list/profile-lockers-list.component')
                              .then(m => m.ProfileLockersListComponent),
        canActivate: [PermissionGuardService],
        data: {
          permissions: [
            PermissionNamesEnum.viewLockersList,
            PermissionNamesEnum.viewLockerDetails
          ]
        }
      },
      {
        path: "users",
        loadComponent: () => import('./profile/user/list/cbox-profile-users-list-container.component')
                              .then(m => m.CBoxProfileUsersListContainerComponent),
        loadChildren: () => [
          {
            path: "internal",
            component: CBoxProfilePlatformUsersListComponent,
            canActivate: [PermissionGuardService],
            data: {
              permissions: [
                PermissionNamesEnum.viewPlatformUsers,
                PermissionNamesEnum.editPlatformUser,
                PermissionNamesEnum.resetPlatformUserPassword,
                PermissionNamesEnum.createPlatformUser
              ]
            },
          },
          {
            path: "api",
            component: CBoxProfileApiUsersListComponent,
            canActivate: [PermissionGuardService],
            data: {
              permissions: [
                PermissionNamesEnum.viewApiUsers,
                PermissionNamesEnum.editApiUser,
                PermissionNamesEnum.createApiUser,
                PermissionNamesEnum.deleteApiUser,
                PermissionNamesEnum.regenerateApiUserCredentials
              ]
            },
          }
        ]
      },
      {
        path: "me",
        loadComponent: () => import('./profile/user/me/cbox-profile-me.component')
                              .then(m => m.CBoxProfileMeComponent),
        // component: CBoxProfileMeComponent,
        loadChildren: () => [
          {
            path: "general",
            component: CBoxProfileMeGeneralComponent
          },
          {
            path: "security",
            component: CBoxProfileMeSecurityComponent
          }
        ]
      },
      {
        path: "orders/drop-off/list",
        loadComponent: () => import('./profile/requests/drop-off/list/cbox-profile-dropoff-requests-list.component')
                              .then(m => m.CBoxProfileDropoffRequestsListComponent),
        canActivate: [PermissionGuardService],
        data: {
          permissions: [
            PermissionNamesEnum.viewDropOffsList,
            PermissionNamesEnum.viewDropOffDetails,
            PermissionNamesEnum.deleteDropOff
          ]
        }
      },
      {
        path: "orders/pick-up/list",
        loadComponent: () => import('./profile/requests/pickup/list/cbox-profile-pickup-requests-list.component')
                              .then(m => m.CBoxProfilePickupRequestsListComponent),
        canActivate: [PermissionGuardService],
        data: {
          permissions: [
            PermissionNamesEnum.viewPickUpsList,
            PermissionNamesEnum.viewPickUpDetails,
            PermissionNamesEnum.createPickUp,
            PermissionNamesEnum.deletePickUp,
          ]
        }
      },
      {
        path: "agents/list",
        loadComponent: () => import('./profile/agents/list/cbox-profile-agent-list.component')
                              .then(m => m.CBoxProfileAgentListComponent),
        canActivate: [PermissionGuardService],
        data: {
          permissions: [
            PermissionNamesEnum.viewAgentsList,
            PermissionNamesEnum.createAgent,
            PermissionNamesEnum.editAgent,
            PermissionNamesEnum.deleteAgent,
            PermissionNamesEnum.revokeAgentAccess,
            PermissionNamesEnum.regenerateAgentCredentials
          ]
        }
      },
      {
        path: "admin",
        loadComponent: () => import('./profile/admin/cbox-profile-admin-container.component')
                              .then(m => m.CBoxProfileAdminContainerComponent),
        loadChildren: () => [
          {
            path: "equipments",
            component: CBoxAdminEquipmentsListComponent,
            canActivate: [PermissionGuardService],
            data: {
              permissions: [
                PermissionNamesEnum.systemAdminAll
              ]
            }
          },
          {
            path: "lockers",
            component: CBoxAdminLockersListComponent,
            canActivate: [PermissionGuardService],
            data: {
              permissions: [
                PermissionNamesEnum.systemAdminAll
              ]
            }
          },
          {
            path: "modules",
            component: CBoxAdminLockerModulesListComponent,
            canActivate: [PermissionGuardService],
            data: {
              permissions: [
                PermissionNamesEnum.systemAdminAll
              ]
            }
          },
          {
            path: "procedures",
            component: CBoxProfileAdminLockerProceduresListComponent,
            canActivate: [PermissionGuardService],
            data: {
              permissions: [
                PermissionNamesEnum.systemAdminAll
              ]
            }
          },
        ]
      },
      {
        path: "reports",
        loadComponent: () => import('./profile/reports/cbox-profile-reports-container.component')
                              .then(m => m.CBoxProfileReportsContainerComponent),
        // component: CBoxProfileReportsContainerComponent,
        children: [
          {
            path: "agents-activity",
            component: CBoxProfileAgentsReportsListComponent,
            canActivate: [PermissionGuardService],
            data: {
              permissions: [
                PermissionNamesEnum.viewReports
              ]
            }
          },
          {
            path: "lockers-activity",
            component: CBoxProfileLockersReportsListComponent,
            canActivate: [PermissionGuardService],
            data: {
              permissions: [
                PermissionNamesEnum.viewReports
              ]
            }
          },
          {
            path: "generated",
            component: CBoxProfileGeneratedReportsComponent,
            canActivate: [PermissionGuardService],
            data: {
              permissions: [
                PermissionNamesEnum.viewReports
              ]
            }
          },
        ]
      },
      {
        path: "zones",
        loadComponent: () => import('./profile/zones/list/cbox-profile-zones-list.component')
                              .then(m => m.CBoxProfileZonesListComponent),
        canActivate: [PermissionGuardService],
        data: {
          permissions: [
            PermissionNamesEnum.viewZonesList,
            PermissionNamesEnum.createZone
          ]
        }
      }
    ]
  },
  {
    path: "c/:identifier",
    loadComponent: () => import('./open/cbox-open-page-container.component')
                          .then(m => m.CBoxOpenPageContainerComponent),
  },
  {
    path: "account/password-reset/:token",
    loadComponent: () => import('./password-reset/cbox-public-password-reset.component')
                          .then(m => m.CBoxPublicPasswordResetComponent),
  },
  {
    path: "account/create/:token",
    loadComponent: () => import('./account-create/cbox-public-account-create.component')
                          .then(m => m.CBoxPublicAccountCreateComponent),
  },
  {
    path: "",
    redirectTo: "profile",
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: "profile"
  }
];
import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, signal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { CBox_GetAgentListIdentifierResponse } from '@server/services/cbox/public/api/v1/resources/agent/types';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { CBox_GetLockerShortData } from '@server/services/cbox/public/api/v1/resources/locker/types';
import { CBox_PublicCreateLockerActivityReportDataParams, CBox_PublicCreateReportResponse } from '@server/services/cbox/public/api/v1/resources/reports/types';
import { CBox_GetSelfUserResponse } from '@server/services/cbox/public/api/v1/resources/user/types';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { markFormDirty } from 'src/helpers/functions';
import { AgentsService } from 'src/services/agents/agents.service';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { LockersService } from 'src/services/lockers/lockers.service';
import { ReportsService } from 'src/services/reports/reports.service';
import { ToastService } from 'src/services/toast/toast.service';
import { UserService } from 'src/services/user/user.service';

@Component({
  selector: 'app-cbox-profile-lockers-activity-report-generate-dialog',
  templateUrl: './cbox-profile-lockers-activity-report-generate-dialog.component.html',
  styleUrls: ["./cbox-profile-lockers-activity-report-generate-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    CalendarModule,
    ButtonModule,
    ReactiveFormsModule,
    CheckboxModule,
    InputSwitchModule,
    InputTextModule,
    MultiSelectModule,
    ProgressSpinnerModule
  ]
})

export class CBoxProfileLockersActivityReportGenerateDialogComponent implements OnInit {

  reportForm: FormGroup;
  userData = signal<CBox_GetSelfUserResponse | undefined>(undefined);
  lockers = signal<CBox_GetLockerShortData[] | undefined>(undefined);
  agents = signal<CBox_GetAgentListIdentifierResponse | undefined>(undefined);
  generating = signal<boolean>(false);

  get emails(): FormArray {
    return this.reportForm.get("emails") as FormArray;
  }

  constructor(
    private api: ApiService,
    private userService: UserService,
    private lockersService: LockersService,
    private fb: FormBuilder,
    private agentsService: AgentsService,
    private toastService: ToastService,
    private reportsService: ReportsService,
    private dialogRef: MatDialogRef<CBoxProfileLockersActivityReportGenerateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      interval?: string[];
    }) {
      console.log(this.data, "DATA");
      this.reportForm = this.fb.group({
        startDate: [new Date(), Validators.required],
        endDate: [new Date(), Validators.required],
        type: [["PDF", "XLSX"], Validators.required],
        emails: this.fb.array([]),
        sendOnMail: [false],
        lockers: [[], Validators.required]
      });

      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
      oneMonthAgo.setHours(0, 0, 0, 0);
      const today = new Date();
      today.setHours(23, 59, 59, 999);
      this.reportForm.patchValue({
        startDate: oneMonthAgo,
        endDate: today
      });

      if (this.data.interval) {
        const start = new Date(this.data.interval[0]).setHours(0, 0, 0, 0);
        const end = new Date(this.data.interval[1]).setHours(23, 59, 59, 999);
        this.reportForm?.patchValue({
          startDate: new Date(start),
          endDate: new Date(end)
        });
      }
    }

  ngOnInit(): void {
    this.init();
  }

  generateReport(): void {
    if (this.reportForm.invalid) {
      console.log(this.reportForm.get("type")?.errors, "TYPE ERRORS");
      markFormDirty(this.reportForm);
      return;
    }

    this.generating.set(true);
    this.api.post<CBox_PublicSuccessResponse<CBox_PublicCreateReportResponse>>("backend/reports/lockers/activity", this.getCreateStructure()).subscribe((response) => {
      this.toastService.showSuccessToast("Confirmare", "Raportul dumneavoastra este in curs de generare");
      this.reportsService.addReportToActiveList(response.data);
      this.generating.set(false);
      this.dialogRef.close();
    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
      this.generating.set(false);
    });
  }

  addEmail(): void {
    const email = new FormControl("", Validators.required);
    if (this.emails.length === 0) {
      email.patchValue(this.userData()?.email as string);
    }
    this.emails.push(email);
  }

  getEmailControlAt(index: number): FormControl {
    return this.emails.at(index) as FormControl;
  }

  private async init(): Promise<void> {
    this.userData.set((await this.userService.getData()).data);
    this.lockers.set(await this.lockersService.getList());
    this.agents.set(await this.agentsService.getList());
    this.addEmail();
  }

  private getCreateStructure(): CBox_PublicCreateLockerActivityReportDataParams {
    return {
      startDate: this.reportForm.get("startDate")?.value,
      endDate: this.reportForm.get("endDate")?.value,
      fileFormats: this.reportForm.get("type")?.value,
      // emails: this.reportForm.get("emails")?.value,
      // sendOnMail: this.reportForm.get("sendOnMail")?.value,
      lockers: this.reportForm.get("lockers")?.value
    };
  }
}
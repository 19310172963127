@let flags = orderData()?.flags;
@if (fetching() === "success") {
  <div class="dialog-header">
    <div class="flex flex-column flex-grow-1">
      <p class="text-xs text-gray-400 m-0">
        @if (orderData()?.uniqueReference?.length) {
          &#8226; Referință unica: {{ orderData()?.uniqueReference }}
        }
        &#8226; ID unic {{ orderData()?.responseId }}
      </p>
      <h2>
        DropOff  AWB
        <span
          matTooltip="Copiază AWB"
          matTooltipPosition="above"
          class="inline-flex cursor-pointer"
          [ngClass]="{
            'text-primary': !awbCopied(),
            'text-green-500': awbCopied(),
          }"
          (click)="copyAwb()"
        >
          {{ orderData()?.awb }}
          @if (awbCopied()) {
            <span class="material-symbols-rounded">check</span>
          } @else {
            <span class="material-symbols-rounded">content_copy</span>
          }
        </span>
      </h2>
      <div [ngStyle]="{
        'background-color': getStatus(orderData()!).color
      }" class="py-1 px-2 w-max border-round-xs font-bold text-white shadow-1">
        <p class="text-xs m-0">
          {{ getStatus(orderData()!).status }}
        </p>
      </div>
    </div>
    <div class="flex align-items-center gap-6">
      @if (
        (
          !flags?.isFinished &&
          !flags?.isCanceled &&
          !flags?.isExpired &&
          flags?.isInLocker &&
          !flags?.isPickedUpByUser &&
          !flags?.isPickedBackByCourier
        ) || !hasActiveFlags()
      ) {
        <p-button
          *appRights="[permissions.deletePickUp]"
          size="small"
          icon="pi pi-trash"
          severity="danger"
          pTooltip="Anulează comanda"
          tooltipPosition="top"
          tooltipZIndex="10000"
          (click)="confirmRequestCancel()"
        />
      }

      <p-button
        mat-dialog-close
        size="small"
        icon="pi pi-times"
        severity="secondary"
        [rounded]="true"
        [text]="true"
      />
    </div>
  </div>
} @else if (fetching() === "fetching") {
  <div class="dialog-header flex align-items-center justify-content-between w-full">
    <div>
      <p-skeleton height=".75rem" width="15rem" borderRadius="100vw"></p-skeleton>
      <p-skeleton borderRadius="100vw" width="25rem" height="1.5rem" styleClass="mt-1"></p-skeleton>
      <p-skeleton height="1.5rem" width="4rem" borderRadius=".25rem" styleClass="mt-1"></p-skeleton>
    </div>
    <div class="flex align-items-center gap-6">
      <p-skeleton height="2.5rem" width="12.5rem" borderRadius=".25rem"></p-skeleton>
      <p-skeleton size="3rem" shape="circle"></p-skeleton>
    </div>
  </div>
} @else if (fetching() === "error") {
  <div class="dialog-header flex justify-content-between align-items-center w-full">
    <h2>Ups!</h2>
    <p-button
      mat-dialog-close
      size="small"
      icon="pi pi-times"
      severity="secondary"
      [rounded]="true"
      [text]="true"
    />
  </div>
}
<p-divider styleClass="my-2"></p-divider>
<mat-dialog-content>
  @if (fetching() === "success") {
    <div class="grid">
      @if (flags?.isInLocker && flags?.isPickedUpByUser && flags?.isIndeterminate) {
        <div class="col-12">
          <div class="flex flex-column align-items-start col-12 w-full gap-2 bg-yellow-100 border-round-xs mb-2 p-4">
            <div class="flex align-items-center gap-2">
              <span class="material-symbols-rounded">warning</span>
              <h2 class="m-0">Atentie!</h2>
            </div>
            <div>
              <p class="m-0">
                A fost detectată o încercare de ridicare a coletului din locker dar sistemul nostru nu a putut detecta cu certitudine
                deschiderea și închiderea ușii. În cazul în care coletul a fost ridicat, vă rugam să specificați acest lucru folosind
                butonul de mai jos.
              </p>
            </div>
            <p-button
              styleClass="p-2 text-xs"
              size="small"
              label="Confirma ridicarea"
              icon="pi pi-check"
              iconPos="left"
              (click)="confirmRequestPickUp($event)"
            ></p-button>
          </div>
        </div>
      }
      <div
        class="col-12 flex align-items-stretch"
        [ngClass]="{
          'md:col-6': orderData()?.flags?.isInLocker
        }"
      >
      <div class="border-round-xs shadow-1 line-height-2 px-4 py-3 md:-mt-3 justify-content-end w-full">
        <h3 class="text-2xl m-0">{{ orderData()?.locker?.name }}</h3>
        @if (fetchingLockerData() === "fetching") {
          <p-skeleton class="w-full" styleClass="mt-1 max-w-30rem"></p-skeleton>
        } @else {
          <p class="m-0 text-gray-400">
            {{ lockerCleanAddress(lockerData()?.addressParts!) }}
          </p>
        }
        <button
          pButton
          size="small"
          class="mt-2"
          styleClass="p-0"
          icon="pi pi-info-circle"
          iconPos="left"
          label="Vezi informații"
          (click)="openLockerDetails()"
        ></button>
      </div>
        <!-- <h3 class="text-2xl font-normal m-0 line-height-2">
          Comanda cu PickUp de la
          <span class="font-bold capitalize">
            {{ orderData()?.locker?.name }}#{{ orderData()?.locker?.id }}.
          </span>
          @if (orderData()?.keptFor) {
            @if (orderData()?.flags?.isInLocker) {
              Comanda este in locker de
              <b>{{ formatTime(orderData()?.keptFor!) }}</b>
            } @else {1
              Comanda a stat in locker
              <b>{{ formatTime(orderData()?.keptFor!) }}</b>
            }
          }
        </h3> -->
        <!-- <p class="text-xs text-gray-400 m-0">
          Dată comandă: {{ orderData()?.createdAt | date: "dd/MM/YYYY" }}
        </p> -->
      </div>
      @if (orderData()?.flags?.isInLocker) {
        <div *appRights="[permissions.viewPickUpOpenCode]" class="col-12 md:col-6 flex align-content-between">
          <div class="flex flex-column border-round-xs shadow-1 line-height-2 px-4 py-3 md:-mt-3 justify-content-end w-full">
            <div class="flex gap-2 align-items-center">
              <h3 class="text-2xl m-0">Cod acces</h3>
              <p-tag styleClass="py-1 px-2 font-bold" severity="success">
                Activ
              </p-tag>
            </div>
            @if (orderData()?.publicReference) {
              <p
                class="text-xs m-0 w-max"
              >
                Link public:
                <span
                  matTooltip="Copiază link"
                  matTooltipPosition="above"
                  class="inline-flex align-items-center gap-1 cursor-pointer"
                  [ngClass]="{
                    'text-gray-400': !publicLinkCopied(),
                    'text-green-500': publicLinkCopied(),
                  }"
                  (click)="copyPublicLink()"
                >
                  @if (publicLinkCopied()) {
                    link-ul a fost copiat
                    <span class="material-symbols-rounded md-18">check</span>
                  } @else {
                    <span>https://www.cbox.ro/c/{{ orderData()?.publicReference }}</span>
                    <span class="material-symbols-rounded md-18">content_copy</span>
                  }
                </span>
              </p>
            }
            @if (!showCode()) {
              <p-button
                class="mt-auto w-max"
                outline="true"
                size="small"
                label="Afișează cod"
                icon="pi pi-eye"
                [loading]="fetchingCode()"
                (click)="this.fetchingCode.set(true); fetchPickupOrderInfo(true);"
              ></p-button>
            } @else {
              <div class="flex align-items-center gap-1">
                @for (letter of splitted(orderData()?.openCode?.code!); track $index) {
                  <div class="flex align-items-center justify-content-center w-2rem h-3rem border-round surface-100">
                    <p class="p-2 font-bold text-xl m-0">{{ showCode() ? letter : "*" }}</p>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      }
      @if (orderData()?.reservedUntil && !orderData()?.pickUpPossibleUntil) {
        <div class="col-12 px-0">
          <div class="flex align-items-center col-12 w-full gap-2 bg-yellow-100 border-round-xs p-2 mt-2">
            <span class="material-symbols-rounded">timer</span>
            <div>
              <p class="m-0 font-bold">
                Comandă rezervată până la data de
                {{ orderData()?.reservedUntil | date: "dd MMMM" }}
                ora
                {{ orderData()?.reservedUntil | date: "HH:mm" }}.
              </p>
            </div>
          </div>
        </div>
      }
      @if (orderData()?.pickUpPossibleUntil && orderData()?.flags?.isInLocker && !isPastDate(orderData()?.pickUpPossibleUntil)) {
        <div class="col-12">
          <div
            class="flex align-items-center gap-2 w-full border-round-xs p-2 mt-2"
            [ngClass]="{
              'bg-green-100': !isPastDate(orderData()?.pickUpPossibleUntil),
              'bg-red-100': isPastDate(orderData()?.pickUpPossibleUntil)
            }"
          >
            <span class="pi pi-check-circle text-2xl"></span>
            <div>
              <p class="m-0 font-bold">
                Comanda poate fi ridicată până la data de
                {{ orderData()?.pickUpPossibleUntil | date: "dd MMMM" }}
                ora
                {{ orderData()?.pickUpPossibleUntil | date: "HH:mm" }}.
                @if (orderData()?.keptFor) {
                  <span>Aceasta se afla în locker de {{ formatTime(orderData()?.keptFor!) }}.</span>
                }
              </p>
            </div>
            <p-button
              *appRights="[permissions.extendPickUp]"
              class="ml-auto"
              styleClass="p-2 text-xs"
              label="Prelungește comanda"
              icon="pi pi-calendar-plus"
              iconPos="left"
              (click)="confirmRequestExtend()"
            ></p-button>
          </div>
        </div>
      }

      @if (orderData()?.pickUpPossibleUntil && orderData()?.flags?.isInLocker && isPastDate(orderData()?.pickUpPossibleUntil)) {
        <div class="col-12">
          <div
            class="flex align-items-center gap-2 w-full border-round-xs p-2 mt-2"
            [ngClass]="{
              'bg-green-100': !isPastDate(orderData()?.pickUpPossibleUntil),
              'bg-red-100': isPastDate(orderData()?.pickUpPossibleUntil)
            }"
          >
            <span class="pi pi-calendar-times text-2xl"></span>
            <div>
              <p class="m-0 font-bold">
                Comandă cu termen de ridicare expirat
                ({{ orderData()?.pickUpPossibleUntil | date: "dd MMMM" }} ora {{ orderData()?.pickUpPossibleUntil | date: "HH:mm" }})
              </p>
            </div>
            <p-button
              *appRights="[permissions.reactivatePickUp]"
              class="ml-auto"
              styleClass="p-2 text-xs"
              label="Reactivează comanda"
              icon="pi pi-play"
              iconPos="left"
              (click)="confirmRequestReactivate()"
            ></p-button>
          </div>
        </div>
      }
        <div class="col-12 sm:col-6 lg:col-3">
          <div class="card h-full shadow-1 flex flex-column justify-content-between align-items-between gap-2 p-4">
            <div class="flex align-items-center gap-2">
              <p-avatar
                shape="circle"
                styleClass="p-1"
              >
                <span class="material-symbols-rounded">open_with</span>
              </p-avatar>
              <p class="m-0 text-lg">Dimensiuni colet</p>
            </div>
            <!-- <p class="text-xs text-gray-400 m-0">Dimensiunile pachetului dumneavoastră.</p> -->
            <p class="text-xl font-bold m-0">
              @if (orderData()?.parcel?.weight) {
                {{ orderData()?.parcel?.weight }} kg
              }
              {{ orderData()?.parcel?.length }}
              <span class="font-normal">x</span>
              {{ orderData()?.parcel?.width }}
              <span class="font-normal">x</span>
              {{ orderData()?.parcel?.height }} cm
            </p>
          </div>
        </div>

        <div class="col-12 sm:col-6 lg:col-3">
          <div class="card h-full shadow-1 flex flex-column justify-content-between align-items-between gap-2 p-4">
            <div class="flex flex-column justify-content-between gap-2 h-full">
              <div class="flex align-items-center gap-2">
                <p-avatar
                  shape="circle"
                  styleClass="p-1 bg-primary"
                >
                  <span class="material-symbols-rounded">package_2</span>
                </p-avatar>
                <div class="flex flex-column">
                  <p class="m-0 text-lg">Cutie solicitată</p>
                  @if (orderData()?.box?.projected?.isActiveReservation) {
                    <span class="bg-yellow-100 shadow-1 border-round-xs text-xs w-max px-1 font-medium">Rezervare activă</span>
                  }
                </div>
              </div>
              <!-- <p class="m-0 text-gray-400 text-xs">Dimensiunea cutiei potrivită pentru coletul dumneavoastră.</p> -->
              <p class="text-xl font-bold m-0">{{ orderData()?.box?.projected?.size }}</p>
            </div>
          </div>
        </div>

        <div [ngClass]="{
          'opacity-60': !orderData()?.box?.used?.size,
        }" class="col-12 sm:col-6 lg:col-3">
          <div class="card h-full shadow-1 flex flex-column justify-content-between align-items-between gap-2 p-4">
            <div class="flex flex-column justify-content-between gap-2 h-full">
              <div class="flex align-items-center gap-2">
                <p-avatar
                  shape="circle"
                  styleClass="p-1 bg-green-500 text-white"
                >
                  <span class="material-symbols-rounded">package_2</span>
                </p-avatar>
                <div class="flex flex-column">
                  <p class="m-0 text-lg">Cutie folosită</p>
                  @if (orderData()?.box?.used?.reason) {
                    <span class="bg-yellow-100 shadow-1 border-round-xs text-xs w-max px-1 font-medium">
                      {{ boxReasons()[orderData()?.box?.used?.reason ?? "NONE"] }}
                    </span>
                  }
                </div>
              </div>
              <!-- <p class="m-0 text-gray-400 text-xs">Dimensiunea cutiei atribuită pentru coletul dumneavoastră.</p> -->
              <p class="text-xl font-bold m-0">{{ orderData()?.box?.used?.size }}</p>
            </div>
          </div>
        </div>

        <div class="col-12 sm:col-6 lg:col-3">
          <div class="card h-full shadow-1 flex flex-column justify-content-between align-items-between gap-2 p-4">
            <div class="flex flex-column justify-content-between gap-2 h-full">
              <div class="flex align-items-center gap-2">
                <p-avatar
                  shape="circle"
                  styleClass="p-1"
                  icon="pi pi-wallet"
                >
                </p-avatar>
                <p class="m-0 text-lg">Cutie facturabilă</p>
              </div>
              <!-- <p class="m-0 text-gray-400 text-xs">Dimensiunea cutiei facturată pentru coletul dumneavoastră.</p> -->
              <p class="text-xl font-bold m-0">
                @if (orderData()?.box?.billable?.size) {
                  {{ orderData()?.box?.billable?.size }}
                }
              </p>
            </div>
          </div>
        </div>
      <div class="col-12 md:col-6">
        <h3 class="text-2xl">Istoric</h3>
        <p-table [tableStyle]="{ 'width': 'min(600px, 100%)' }" [value]="orderData()?.history || []" styleClass="p-datatable-sm">
          <ng-template pTemplate="header">
            <tr>
              <th>Stare</th>
              <th>Actualizat la</th>
              <th>Declanșat de</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-event>
            <tr>
              <td>{{ event.description }}</td>
              <td>{{ event.occurredAt | date: "dd MMMM HH:mm"}}</td>
              <td>{{ event.triggeredByName }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="col-12 md:col-6">
        <h3 class="text-2xl">Notificări</h3>
        <div class="flow">
          @for (address of orderData()?.notify?.email; track $index) {
            @if (address.enabled) {
              <div class="flex gap-2 align-items-start justify-content-start">
                <p-avatar shape="circle" size="large">
                  <span class="material-symbols-rounded">email</span>
                </p-avatar>
                <div class="flex flex-column">
                  <div class="flex align-items-center gap-1">
                    <p class="font-bold m-0">Email - {{ address.email }}</p>
                    <p-tag styleClass="py-1 px-2 font-bold" severity="success">
                      Activ
                    </p-tag>
                  </div>
                  @for (email of address.sendHistory; track $index) {
                    @if ($index < emailLimit()) {
                      <p class="m-0 text-gray-400">
                        Trimis la {{ email.sentAt | date: "dd MMMM HH:mm" }}
                      </p>
                    }
                  }
                  @if (address.sendHistory.length > emailLimit()) {
                    <p
                      class="m-0 text-gray-400 underline cursor-pointer text-primary"
                      (click)="emailLimit.set(10000)"
                    >
                      vezi încă {{ address.sendHistory.length - 2 }} trimiteri
                    </p>
                  }
                  @if (address.sendHistory.length) {
                    @if (
                      !orderData()?.flags?.isExpired &&
                      !orderData()?.flags?.isFinished &&
                      !orderData()?.flags?.isCanceled
                    ) {
                      <button
                        pButton
                        class="p-2 text-xs w-max"
                        size="small"
                        label="Retrimite email"
                        icon="pi pi-refresh"
                        iconPos="left"
                        [loading]="sendingEmail()"
                        (click)="confirmEmailResend()"
                      ></button>
                    }
                  } @else {
                    <button
                      pButton
                      class="p-2 text-xs w-max mt-1"
                      size="small"
                      label="Actualizează adresa de email"
                      icon="pi pi-pencil"
                      iconPos="left"
                      (click)="updateEmailAddress()"
                    ></button>
                  }
                </div>
              </div>
            }
          } @empty {
            <div class="flex gap-2 align-items-center">
              <p-avatar shape="circle" size="large">
                <span class="material-symbols-rounded">email</span>
              </p-avatar>
              <div class="flex flex-column">
                <div class="flex align-items-center gap-1">
                  <p class="font-bold m-0">Email</p>
                  <p-tag styleClass="py-1 px-2 font-bold" severity="danger">
                    Inactiv
                  </p-tag>
                </div>
              </div>
            </div>
          }

          @for (phoneNumber of orderData()?.notify?.sms; track $index) {
            @if (phoneNumber.enabled) {
              <div class="flex gap-2 align-items-start justify-content-start">
                <p-avatar shape="circle" size="large">
                  <span class="material-symbols-rounded">sms</span>
                </p-avatar>
                <div class="flex flex-column">
                  <div class="flex align-items-center gap-1">
                    <p class="font-bold m-0">SMS - {{ phoneNumber.phone }}</p>
                    <p-tag styleClass="py-1 px-2 font-bold" severity="success">
                      Activ
                    </p-tag>
                  </div>
                  @for (sms of phoneNumber.sendHistory; track $index) {
                    @if ($index < smsLimit()) {
                      <p class="m-0 text-gray-400">
                        Trimis la {{ sms.sentAt | date: "dd MMMM HH:mm" }}
                      </p>
                    }
                  }
                  @if (phoneNumber.sendHistory.length > smsLimit()) {
                    <p
                      class="m-0 text-gray-400 underline cursor-pointer text-primary"
                      (click)="smsLimit.set(10000)"
                    >
                      vezi încă {{ phoneNumber.sendHistory.length - 2 }} trimiteri
                    </p>
                  }
                  @if (phoneNumber.sendHistory.length) {
                    @if (
                      !orderData()?.flags?.isExpired &&
                      !orderData()?.flags?.isFinished &&
                      !orderData()?.flags?.isCanceled
                    ) {
                      <button
                        pButton
                        class="p-2 text-xs w-max"
                        size="small"
                        label="Retrimite SMS"
                        icon="pi pi-refresh"
                        iconPos="left"
                        [loading]="sendingSMS()"
                        (click)="confirmSMSResend()"
                      ></button>
                    }
                  } @else {
                    <button
                      pButton
                      class="p-2 text-xs w-max mt-1"
                      size="small"
                      label="Actualizează număr de telefon"
                      icon="pi pi-pencil"
                      iconPos="left"
                    ></button>
                  }
                </div>
              </div>
            }
          } @empty {
            <div class="flex gap-2 align-items-center">
              <p-avatar shape="circle" size="large">
                <span class="material-symbols-rounded">sms</span>
              </p-avatar>
              <div class="flex flex-column">
                <div class="flex align-items-center gap-1">
                  <p class="font-bold m-0">SMS</p>
                  <p-tag styleClass="py-1 px-2 font-bold" severity="danger">
                    Inactiv
                  </p-tag>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      <div *appRights="[permissions.systemAdminAll]" class="col-12">
        <h2>Acțiuni comandă</h2>
        <p-table
          styleClass="p-datatable-sm"
          [value]="orderData()?.actionLog || []"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Tip acțiune</th>
              <th></th>
              <th>Data</th>
              <th>Utilizator</th>
              <th>Detalii</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-action>
            <tr>
              <td>{{ action.type }}</td>
              <td>
                @switch (action.type) {
                  @case (actionsEnum.CREATE) {
                    <div class="py-1 px-2 w-max border-round-xs font-bold text-white shadow-1 bg-green-500 text-xs">
                      <p class="text-xs m-0">
                        Create
                      </p>
                    </div>
                  }

                  @case (actionsEnum.UPDATE) {
                    <div class="py-1 px-2 w-max border-round-xs font-bold text-white shadow-1 bg-blue-500 text-xs">
                      <p class="text-xs m-0">
                        Update
                      </p>
                    </div>
                  }

                  @case (actionsEnum.UPDATE_PHONE) {
                    <div class="py-1 px-2 w-max border-round-xs font-bold text-white shadow-1 bg-blue-500 text-xs">
                      <p class="text-xs m-0">
                        Update
                      </p>
                    </div>
                  }

                  @case (actionsEnum.UPDATE_EMAIL) {
                    <div class="py-1 px-2 w-max border-round-xs font-bold text-white shadow-1 bg-blue-500 text-xs">
                      <p class="text-xs m-0">
                        Update
                      </p>
                    </div>
                  }

                  @case (actionsEnum.RESEND_SMS) {
                    <div class="py-1 px-2 w-max border-round-xs font-bold text-white shadow-1 bg-yellow-500 text-xs">
                      <p class="text-xs m-0">
                        Resend
                      </p>
                    </div>
                  }

                  @case (actionsEnum.RESEND_EMAIL) {
                    <div class="py-1 px-2 w-max border-round-xs font-bold text-white shadow-1 bg-yellow-500 text-xs">
                      <p class="text-xs m-0">
                        Resend
                      </p>
                    </div>
                  }

                  @case (actionsEnum.CODE_ACCESS) {
                    <div class="py-1 px-2 w-max border-round-xs font-bold text-white shadow-1 bg-green-500 text-xs">
                      <p class="text-xs m-0">
                        Acces
                      </p>
                    </div>
                  }

                  @case (actionsEnum.EXTEND) {
                    <div class="py-1 px-2 w-max border-round-xs font-bold text-white shadow-1 bg-yellow-500 text-xs">
                      <p class="text-xs m-0">
                        Extend
                      </p>
                    </div>
                  }

                  @case (actionsEnum.CANCEL) {
                    <div class="py-1 px-2 w-max border-round-xs font-bold text-white shadow-1 bg-red-500 text-xs">
                      <p class="text-xs m-0">
                        Delete
                      </p>
                    </div>
                  }
                }
              </td>
              <td>{{ action.performedAt | date: "dd MMMM HH:mm" }}</td>
              <td>
                <p class="text-xs text-gray-400 m-0">{{ action.performedBy.identifier }}</p>
                <b class="text-lg">{{ action.performedBy.name }}</b>
                <p class="m-0 text-gray-400 text-xs">
                  {{ action.occurredAt | date: "dd MMMM HH:mm" }}
                </p>
              </td>
              <td>
                @switch (action.type) {
                  @case (actionsEnum.CREATE) {
                    <p class="m-0">Comanda a fost creată.</p>
                  }

                  @case (actionsEnum.UPDATE) {
                    <p class="m-0">Comanda a fost updatată.</p>
                  }

                  @case (actionsEnum.UPDATE_PHONE) {
                    <p class="m-0">
                      <b>{{ action.data.phone.old }}</b>
                      →
                      <b>{{ action.data.phone.new }}</b>.
                    </p>
                  }

                  @case (actionsEnum.UPDATE_EMAIL) {
                    <p class="m-0">
                      <b>{{ action.data.email.old }}</b>
                      →
                      <b>{{ action.data.email.new }}</b>.
                    </p>
                  }

                  @case (actionsEnum.RESEND_SMS) {
                    <p class="m-0">
                      A fost trimis SMS pe numarul de telefon
                      <b>{{ action.data.phone }}</b>.
                    </p>
                  }

                  @case (actionsEnum.RESEND_EMAIL) {
                    <p class="m-0">
                      A fost trimis email pe numarul de telefon
                      <b>{{ action.data.email }}</b>.
                    </p>
                  }

                  @case (actionsEnum.CODE_ACCESS) {
                    <p class="m-0">A fost accesat codul de acces al comenzii.</p>
                  }

                  @case (actionsEnum.EXTEND) {
                    <p class="m-0">
                      <b>{{ action.data.until.old | date: "dd MMMM HH:mm" }}</b>
                      →
                      <b>{{ action.data.until.new | date: "dd MMMM HH:mm"}}</b>.
                    </p>
                  }

                  @case (actionsEnum.CANCEL) {
                    <p class="m-0">
                      Comanda a fost anulată.
                    </p>
                  }
                }
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  } @else if (fetching() === "fetching") {
    <div class="grid">
      <div
        class="col-12 md:col-6 align-self-start"
      >
        <p-skeleton height="1.875rem" width="35rem"></p-skeleton>
        <p-skeleton width="10rem" styleClass="mt-2"></p-skeleton>
      </div>

      <div class="col-12 md:col-6 md:-mt-3 justify-content-end">
        <p-skeleton styleClass="w-full" borderRadius=".25rem" height="8rem"></p-skeleton>
      </div>

      <div class="col-12 mt-1">
        <p-skeleton height="2.5rem" borderRadius=".25rem"></p-skeleton>
      </div>

      <div class="col-12 sm:col-6 lg:col-3">
        <p-skeleton height="7.25rem" styleClass="w-full" borderRadius=".25rem"></p-skeleton>
      </div>

      <div class="col-12 sm:col-6 lg:col-3">
        <p-skeleton height="7.25rem" styleClass="w-full" borderRadius=".25rem"></p-skeleton>
      </div>

      <div class="col-12 sm:col-6 lg:col-3">
        <p-skeleton height="7.25rem" styleClass="w-full" borderRadius=".25rem"></p-skeleton>
      </div>

      <div class="col-12 sm:col-6 lg:col-3">
        <p-skeleton height="7.25rem" styleClass="w-full" borderRadius=".25rem"></p-skeleton>
      </div>

      <div class="col-12 sm:col-6">
        <p-skeleton height="1.5rem" width="8rem" borderRadius="100vw" styleClass="mt-4"></p-skeleton>
        <p-skeleton height="8rem" styleClass="w-full mt-4" borderRadius=".25rem"></p-skeleton>
      </div>

      <div class="col-12 sm:col-6">
        <p-skeleton height="1.5rem" width="8rem" borderRadius="100vw" styleClass="mt-4"></p-skeleton>
        <div class="flex align-items-center gap-2 mt-4">
          <p-skeleton size="3rem" shape="circle"></p-skeleton>
          <p-skeleton height="1.5rem" width="10rem" borderRadius="100vw"></p-skeleton>
        </div>

        <div class="flex align-items-center gap-2 mt-4">
          <p-skeleton size="3rem" shape="circle"></p-skeleton>
          <p-skeleton height="1.5rem" width="10rem" borderRadius="100vw"></p-skeleton>
        </div>
      </div>
    </div>
  } @else if (fetching() === "error") {
    <div class="grid py-4">
      <div class="col-12 flex flex-column justify-content-center text-center gap-4">
        <span class="pi pi-times-circle text-red-500 text-8xl"></span>
        <p class="text-red-500 font-bold text-2xl line-height-2">A apărut o eroare la încărcarea datelor.<br>Vă rugăm să reîncercați.</p>
        <p-button
          size="large"
          icon="pi pi-refresh"
          iconPos="left"
          [label]="
            fetchRetryTimer ? 'Reîncearcă în ' + fetchRetryTimer + 's' : 'Reîncearcă'

          "
          [disabled]="!!fetchRetryTimer"
          (click)="retryFetch()"
        ></p-button>
      </div>
    </div>
  }

</mat-dialog-content>

<p-confirmPopup>
  <ng-template pTemplate="content" let-message>
    <p>{{ message.message }}</p>
  </ng-template>
</p-confirmPopup>
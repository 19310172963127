import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, InjectionToken, isDevMode, PLATFORM_ID } from "@angular/core";
import { CookiesService } from "../cookies/cookies.service";
import { isPlatformServer } from "@angular/common";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class CredentialsInterceptor implements HttpInterceptor {

  private url = "http://10.10.0.141:3040";

  constructor(
    private cookiesService: CookiesService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
  ){
    if (isPlatformServer(this.platformId) && isDevMode()) {
      this.url = "http://10.10.0.122:3040";
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq: HttpRequest<any>;
    if (isPlatformServer(this.platformId) && !isDevMode()) {
      if (req.url.includes("assets")) {
        return next.handle(req);
      }
      const cookies = this.cookiesService.get();
      let url = req.url.replace(/^http.+?backend/g, this.url);
      url = url.replace(/^backend/g, this.url);
      console.log(url);
      newReq = req.clone({
        url,
        withCredentials: true,
        setHeaders: {
          Cookie: cookies,
        },
      });
    } else {
      newReq = req.clone({
        withCredentials: true,
      });
    }
    return next.handle(newReq);
  }

}
import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_GetLockerShortData } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { MultiSelectModule } from "primeng/multiselect";
import { LockersService } from "src/services/lockers/lockers.service";
import { CBoxAdminDvrTemplateComponent } from "../../templates/dvr/cbox-admin-dvr-template.component";
import { CBoxAdminRouterTemplateComponent } from "../../templates/router/cbox-admin-router-template.component";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_AdminEquipmentListItem, CBox_AdminEquipmentRequestData, CBox_EquipmentType, CBox_EquipmentTypeEnum, CBox_EquipmentTypeResponse } from "@server/services/cbox/public/api/v1/resources/internal/equipment/types";
import { markFormDirty } from "src/helpers/functions";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastService } from "src/services/toast/toast.service";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ProgressSpinnerModule } from "primeng/progressspinner";

@Component({
  selector: "app-cbox-admin-equipment-create",
  templateUrl: "./cbox-admin-equipment-create.component.html",
  styleUrls: ["./cbox-admin-equipment-create.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ButtonModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    InputTextModule,
    ReactiveFormsModule,
    CBoxAdminDvrTemplateComponent,
    CBoxAdminRouterTemplateComponent,
    InputTextareaModule,
    ProgressSpinnerModule
  ]
})

export class CBoxAdminEquipmentCreateDialogComponent implements OnInit {

  lockers = signal<CBox_GetLockerShortData[] | undefined>(undefined);
  equipmentTypes = signal<CBox_EquipmentTypeResponse | undefined>(undefined);
  selectedType = signal<CBox_EquipmentType | undefined>(undefined);
  equipmentTypeEnum = CBox_EquipmentTypeEnum;
  equipmentForm?: FormGroup;
  loading = signal(true);
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private lockersService: LockersService,
    private api: ApiService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxAdminEquipmentCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public equipmentToCopy?: CBox_AdminEquipmentListItem) {
      this.form = this.fb.group({
        // internalSequenceNumber: ["", Validators.required],
        typeId: [undefined, Validators.required],
        internalSerial: ["", Validators.required],
        notes: [""],
        manufacturerSerial: ["", Validators.required],
        modelId: [undefined, Validators.required],
        // pairWithLocker: [false],
        // lockerId: [undefined, Validators.required]
      });
    }

  ngOnInit(): void {
    this.init();
  }

  // pairWithLockerToggled(): void {
  //   const f = this.form;
  //   if (f.get("pairWithLocker")?.value) {
  //     f.get("locker")?.enable();
  //   } else {
  //     f.get("locker")?.disable();
  //   }
  // }

  patchForm(form: FormGroup): void {
    this.equipmentForm = form;
  }

  typeSelected(typeId: CBox_EquipmentType["id"], modelId?: number): void {
    const f = this.form;
    this.equipmentForm = undefined;
    f.get("modelId")?.setValue(undefined);
    f.get("modelId")?.enable();
    const selectedType = this.equipmentTypes()?.find((component) => component.id === typeId);
    this.selectedType.set(selectedType);
    if (selectedType?.models?.length === 1) {
      f.get("modelId")?.setValue(selectedType.models[0].id);
      f.get("modelId")?.disable();
    } else {
      f.get("modelId")?.patchValue(modelId);
    }
  }

  create(): void {
    if (this.form.invalid || this.equipmentForm?.invalid) {
      markFormDirty(this.form);
      markFormDirty(this.equipmentForm);
      return;
    }

    this.api.post("backend/internal/equipments", this.getCreateStructure()).subscribe(() => {
      this.toastService.showSuccessToast("Confirmare", "Echipamentul a fost creat cu succes.");
      this.dialogRef.close();
    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
    });
  }

  private async init(): Promise<void> {
    this.lockers.set(await this.lockersService.getList());
    this.fetchEquipmentTypes();
  }

  private fetchEquipmentTypes(): void {
    console.log("FETCHING TYPES");
    this.loading.set(true);
    this.api.get<CBox_PublicSuccessResponse<CBox_EquipmentTypeResponse>>("backend/internal/equipments/types").subscribe((response) => {
      console.log(response, "EQUIPMENT TYPES RESPONSE");
      this.equipmentTypes.set(response.data);
      if (this.equipmentToCopy) {
        this.form.patchValue({
          typeId: this.equipmentToCopy.type.id,
          internalSerial: this.equipmentToCopy.internalSerial,
          manufacturerSerial: this.equipmentToCopy.manufacturerSerial,
          notes: this.equipmentToCopy.notes
        })
        this.typeSelected(this.equipmentToCopy.type.id, this.equipmentToCopy.model.id);
      }
      this.loading.set(false);
    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
    });
  }

  private getCreateStructure(): CBox_AdminEquipmentRequestData {
    const controls = this.form.controls;
    const data: CBox_AdminEquipmentRequestData = {
      internalSerial: controls["internalSerial"].value,
      notes: controls["notes"].value,
      modelId: controls["modelId"].value,
      manufacturerSerial: controls["manufacturerSerial"].value,
      // lockerId: controls["pairWithLocker"] ? {
      //   id: controls["lockerId"].value
      // } : undefined,
      ...this.equipmentForm?.value
    }

    return data;
  }
}
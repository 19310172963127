import { CommonModule, isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { markFormDirty } from "src/helpers/functions";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ToastService } from "src/services/toast/toast.service";
import { CBox_AdminActionUpdateLockerRequestData, CBox_AdminLockerData } from "@server/services/cbox/public/api/v1/resources/internal/locker/types";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TableModule } from "primeng/table";
import { CBox_AdminModuleListItemDataResponse } from "@server/services/cbox/public/api/v1/resources/internal/locker_module/types";
import { TagModule } from "primeng/tag";
import { CBoxAdminLockerModuleUpdateComponent } from "app/profile/admin/modules/dialogs/update/cbox-admin-locker-module-update.component";
import { first } from "rxjs";

@Component({
  selector: "app-cbox-admin-locker-config-dialog",
  templateUrl: "./cbox-admin-locker-config-dialog.component.html",
  styleUrls: ["./cbox-admin-locker-config-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ProgressSpinnerModule,
    DropdownModule,
    MatTooltipModule,
    TableModule,
    TagModule,
  ]
})

export class CBoxAdminLockerConfigDialogComponent implements OnInit {

  form: FormGroup;
  modules = signal<CBox_AdminModuleListItemDataResponse[] | undefined>(undefined);
  alreadyExistentModules = signal<FormArray<never>>(new FormArray([]));
  lockerData = signal<CBox_AdminLockerData | undefined>(undefined);
  loading = signal(true);

  get modulesArray(): FormArray {
    return this.form.get("modules") as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CBoxAdminLockerConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public locker: CBox_AdminLockerData,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.form = this.fb.group({
        id: [{
          value: "",
          disabled: true
        }, Validators.required],
        identifier: [{
          value: "",
          disabled: true
        }, Validators.required],
        name: ["", Validators.required],
        locality: ["", Validators.required],
        county: ["", Validators.required],
        postalCode: ["", Validators.required],
        street: ["", Validators.required],
        number: ["", Validators.required],
        restOfAddress: [""],
        latitude: ["", Validators.required],
        longitude: ["", Validators.required],
        modules: this.fb.array([])
      });
    }

  ngOnInit(): void {
    this.init();
  }

  private async init(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      this.loadData();
    }
  }

  loadData(): void {
    this.fetchLockerData();
    this.fetchModules();
  }

  update(): void {
    if (this.form.invalid) {
      markFormDirty(this.form);
      return;
    }

    this.loading.set(true);
    this.api.patch("backend/internal/lockers?lockerIdentifier=" + this.locker.externalIdentifier, this.getUpdateStructure()).subscribe(() => {
      this.toastService.showSuccessToast("Confirmare", "Locker-ul a fost configurat cu succes!");
      this.dialogRef.close();
      this.loading.set(false);
    }, (e: HttpErrorResponse) => {
      this.loading.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }

  getFormAtIndex(index: number): FormGroup {
    return this.modulesArray.at(index) as FormGroup;
  }

  getExistentModuleForm(index: number): FormGroup {
    return this.alreadyExistentModules()?.at(index) as FormGroup;
  }

  deleteModuleAt(index: number): void {
    this.modulesArray.removeAt(index);
  }

  updateModule(module: FormGroup): void {
    const dialog = this.dialog.open(CBoxAdminLockerModuleUpdateComponent, {
      data: module.value,
      width: "min(600px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe((m) => {
      console.log(m, "MODULE AFTER UPDATE");
      if (m) {
        module.patchValue(m);
      }
    });
  }

  addModule(formValue?: CBox_AdminActionUpdateLockerRequestData["body"]["modules"][0]): void {
    const moduleForm = this.fb.group({
      position: ["", Validators.required],
      moduleId: [undefined, Validators.required],
      boardCardAddress: [undefined, Validators.required],
      serial: [""]
    });

    if (formValue) {
      moduleForm.patchValue(formValue as any);
      this.alreadyExistentModules.update(modules => {
        modules?.push(moduleForm as never);

        return modules;
      });
    } else {
      this.modulesArray.push(moduleForm);
    }

  }

  private fetchLockerData(): void {
    this.api.get<CBox_PublicSuccessResponse<CBox_AdminLockerData>>("backend/internal/locker?lockerIdentifier=" + this.locker.externalIdentifier).subscribe((response) => {
      this.patchForm(response.data);
      this.loading.set(false);
    }, (e: HttpErrorResponse) => {
      this.loading.set(false);
      handlePublicApiError(e, this.toastService);
    })
  }

  private fetchModules(): void {
    this.api.get<CBox_PublicSuccessResponse<CBox_AdminModuleListItemDataResponse[]>>("backend/internal/modules/list?filter=NOT_ATTACHED").subscribe((response) => {
      this.modules.set(response.data);
      this.loading.set(false);
    }, (e: HttpErrorResponse) => {
      this.loading.set(false);
      handlePublicApiError(e, this.toastService);
    })
  }

  private patchForm(locker: CBox_AdminLockerData): void {
    this.form.patchValue({
      id: locker.lockerId,
      identifier: locker.externalIdentifier,
      name: locker.name,
      locality: locker.addressParts.localityName,
      county: locker.addressParts.countyName,
      postalCode: locker.addressParts.postalCode,
      street: locker.addressParts.street,
      number: locker.addressParts.streetNumber,
      restOfAddress: locker.addressParts.restOfAddress,
      latitude: locker.coordinates.latitude,
      longitude: locker.coordinates.longitude,
    });

    for (const module of locker.modules) {
      this.addModule(module);
    }
  }

  private getUpdateStructure(): CBox_AdminActionUpdateLockerRequestData["body"] {
    const f = this.form.getRawValue();
    const modules = [...this.modulesArray.value, ...this.alreadyExistentModules()!.value];
    const data: CBox_AdminActionUpdateLockerRequestData["body"] = {
      id: f.id,
      name: f.name,
      isActive: false,
      address: {
        localityString: f.locality,
        countyString: f.county,
        postalCode: f.postalCode,
        street: f.street,
        streetNumber: f.number,
        restOfAddress: f.restOfAddress,
        latitude: +f.latitude,
        longitude: +  f.longitude
      },
      modules: modules.map((m: any) => {
        console.log(m, "MODULE IN MAP");
        return {
          moduleId: +m.moduleId,
          position: +m.position,
          boardCardAddress: +m.boardCardAddress,
        }
      })
    };

    console.log(data, "LOCKER CONFIG UPDATE STRUCTURE");

    return data;
  }

  addToDo(): void {
    const dialog = this.dialog.open
  }
}
import { CommonModule, isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_PublicConfigurationPickUpMaxStorageDuration } from "@server/services/cbox/public/api/v1/enforcers/company_config/types";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_ActionUpdatePickUpParams, CBox_GetPickUpDataResponse } from "@server/services/cbox/public/api/v1/resources/pick_up/types";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { SkeletonModule } from "primeng/skeleton";
import { TooltipModule } from "primeng/tooltip";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { ToastService } from "src/services/toast/toast.service";

@Component({
  selector: "app-cbox-profile-pick-up-request-extend-dialog",
  templateUrl: "./cbox-profile-pick-up-request-extend-dialog.component.html",
  styleUrls: ["./cbox-profile-pick-up-request-extend-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ButtonModule,
    SkeletonModule,
    CalendarModule,
    ProgressSpinnerModule,
    CalendarModule,
    ReactiveFormsModule,
    TooltipModule
  ]
})

export class CBoxProfilePickUpRequestExtendDialogComponent implements OnInit {
  orderData = signal<CBox_GetPickUpDataResponse | undefined>(undefined);
  extendDate = new FormControl(new Date(), Validators.required);
  today = signal(new Date());
  maxStorageDate = signal(new Date());
  minDate = signal(new Date());
  maxStorageDuration?: number;

  fetching = signal(true);
  extending = signal(false);

  selectedPeriod = signal<{label: string; hours: number} | undefined>(undefined);
  extendPeriods = signal<{label: string; hours: number}[]>([
    {
      label: "1 zi",
      hours: 24
    },
    {
      label: "2 zile",
      hours: 48
    },
    {
      label: "3 zile",
      hours: 72
    },
    {
      label: "Calendar",
      hours: 0
    },
  ]);

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private configuration: ConfigurationService,
    private dialogRef: MatDialogRef<CBoxProfilePickUpRequestExtendDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private responseId: string,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) { }

  ngOnInit(): void {
    this.init();
  }

  extendRequest(): void {
    if (!this.selectedPeriod()) {
      this.toastService.showErrorToast("Eroare", "Vă rugam să selectați perioada de extindere a comenzii!")
      return;
    }
    if (this.selectedPeriod()?.hours === 0 && this.extendDate.invalid) {
      this.toastService.showErrorToast("Eroare", "Vă rugăm să selectați data până la care doriți să extindeți comanda!")
      return;
    }
    this.extending.set(true);
    this.api.patch("backend/request/pick-up?responseId=" + this.responseId, this.getExtendStructure()).subscribe((response) => {
      this.dialogRef.close();
      this.toastService.showSuccessToast("Confirmare", "Comanda a fost reactivată cu succes!");
      this.extending.set(false);
    }, (e: HttpErrorResponse) => {
      this.extending.set(false);
      handlePublicApiError(e, this.toastService);
    })
  }

  addHours(date: string, hours: number): Date {
    return new Date(new Date(date).getTime() + hours * 60 * 60 * 1000);
  }

  periodSelected(period: {label: string; hours: number}): void {
    if (period.hours > 0 && this.addHours(this.orderData()?.pickUpPossibleUntil!, period.hours) > this.maxStorageDate()) {
      return;
    }
    this.selectedPeriod.set(period);
  }

  private async init(): Promise<void> {
    this.maxStorageDuration = (await this.configuration.getConfigKey(
                                   'request.pickUp.maxStorageDuration') as
                               CBox_PublicConfigurationPickUpMaxStorageDuration)
                                  .value;
    if (isPlatformBrowser(this.platformId)) {
      this.fetchOrderData();
    }
  }

  private fetchOrderData(): void {
    this.api.get<CBox_PublicSuccessResponse<CBox_GetPickUpDataResponse>>("backend/request/pick-up?responseId=" + this.responseId).subscribe((response) => {
      this.orderData.set(response.data);
      this.fetching.set(false);
      this.maxStorageDate.set(this.addHours(this.orderData()?.pickUpPossibleUntil!, this.maxStorageDuration as number));
      console.log(this.maxStorageDate, "MAX STORAGE DATE");
      this.minDate.set(new Date(this.orderData()?.pickUpPossibleUntil!));
      this.extendDate.setValue(new Date(this.orderData()?.pickUpPossibleUntil!));
    }, (e: HttpErrorResponse) => {
      this.fetching.set(false);
      this.dialogRef.close();
      handlePublicApiError(e, this.toastService);
    });
  }

  private getExtendStructure(): CBox_ActionUpdatePickUpParams["body"] {
    const isPredefinedPeriodSelected = this.selectedPeriod()?.hours !== 0;
    const startDate = new Date(this.orderData()?.pickUpPossibleUntil!);
    let offset = 0;

    if (!isPredefinedPeriodSelected) {
      offset = Math.floor(
        (new Date(this.extendDate.value!).getTime() - startDate.getTime()) / 1000 / 60
      );
    } else {
      offset = this.selectedPeriod()!.hours * 60;
    }

    return {
      retention: {
        reenable: false,
        extend: !isPredefinedPeriodSelected
          ? { until: new Date(this.extendDate.value!).toISOString() }
          : {
              offsetStartPoint: "original",
              offset
            }
      }
    };
  }
}
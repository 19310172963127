import { CommonModule } from "@angular/common";
import { Component, Injector, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { MenuModule } from "primeng/menu";
import { ProgressBarModule } from "primeng/progressbar";
import { TableModule } from "primeng/table";
import { first } from "rxjs";
import { ProfileService } from "src/services/profile/profile.service";
import { getLastActiveMessage, lockerCleanAddress } from "src/helpers/functions";
import { CBox_GetLockerBaseData } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { MultiSelectModule } from "primeng/multiselect";
import { CalendarModule } from "primeng/calendar";
import { CBoxProfileAgentSessionInfoDialogComponent } from "./dialogs/session-info/cbox-profile-agent-session-info-dialog.component";
import { CBox_PublicGetAgentActivityDataListItem, CBox_PublicGetAgentActivityDataListParams } from "@server/services/cbox/public/api/v1/resources/reports/types";
import { CBox_ActionCreateAgentPermittedActionEnum } from "@server/services/cbox/public/api/v1/resources/agent/types";
import { CBoxProfileAgentsActivityReportGenerateDialogComponent } from "../dialogs/generate/agents-activity/cbox-profile-agents-activity-report-generate-dialog.component";
import { TableWithFilters } from "app/shared/table-with-filters";

@Component({
  selector: "app-cbox-profile-agents-reports-list",
  templateUrl: "./cbox-profile-agents-reports-list.component.html",
  styleUrls: ["./cbox-profile-agents-reports-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    ReactiveFormsModule,
    MenuModule,
    ProgressBarModule,
    InputTextModule,
    MultiSelectModule,
    CalendarModule
  ]
})

export class CBoxProfileAgentsReportsListComponent extends TableWithFilters<CBox_PublicGetAgentActivityDataListItem, CBox_PublicGetAgentActivityDataListParams> implements OnInit {

  filtersForm: FormGroup;
  dataFetchUrl = "reports/agents/activity";
  sessionFiltersKey = "profile/reports/agents/filters";
  sessionFiltersToSave = ["page", "lockers", "interval", "agents"];
  localStorageFiltersKey = "profile/reports/agents/filters";
  localStorageFiltersToSave = ["pageSize"];
  defaultFilters = {
    lockers: [],
    page: 1,
    pageSize: 25,
    agents: [],
    interval: [this.oneMonthAgo, this.today]
  };
  agentActions = CBox_ActionCreateAgentPermittedActionEnum;

  constructor(
    inj: Injector,
    private profileService: ProfileService,
    private fb: FormBuilder,
    private dialog: MatDialog) {
      super(inj);
      this.filtersForm = this.fb.group({
        page: [1],
        pageSize: [25],
        lockers: [[]],
        interval: [undefined],
        agents: [[]]
      });
    }

  ngOnInit(): void {
    this.baseInit();
  }

  getLastActiveMessage(lastUsedAt: string): string {
    return getLastActiveMessage(lastUsedAt);
  }

  openSessionInfo(element: CBox_PublicGetAgentActivityDataListItem): void {
    const dialog = this.dialog.open(CBoxProfileAgentSessionInfoDialogComponent, {
      data: element.procedureId,
      width: "min(800px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.loading.set(true);
      this.fetchData();
    });
  }

  generateReport(): void {
    const dialog = this.dialog.open(CBoxProfileAgentsActivityReportGenerateDialogComponent, {
      data: {
        interval: this.filtersForm.value.interval
      },
      minWidth: "min(600px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.loading.set(true);
      this.fetchData();
    });
  }

  async init(): Promise<void> {
    this.profileService.setTitle("Rapoarte agenți");
  }

  lockerCleanAddress(addressParts: CBox_GetLockerBaseData["addressParts"]): string {
    return lockerCleanAddress(addressParts);
  }

  getActionParcelCount(
      element: CBox_PublicGetAgentActivityDataListItem,
      actionType:
          CBox_PublicGetAgentActivityDataListItem["actions"]["counts"][0]["type"]):
      number {
    return element.actions.counts.find((a: CBox_PublicGetAgentActivityDataListItem["actions"]["counts"][0]) => a.type === actionType)?.count ?? 0;
  }

  getSearchStructure() {
    return Object.entries({
      page: +this.filtersForm.value.page,
      pageSize: +this.filtersForm.value.pageSize,
      "agents[]": this.filtersForm.value.agents,
      "lockers[]": this.filtersForm.value.lockers,
      startDate: this.filtersForm.value.interval[0].toISOString(),
      endDate: this.filtersForm.value.interval[1].toISOString()
    })
    .filter(([_, value]) => value?.length || !!value)
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as any);
  }
}
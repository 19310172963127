import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { CBox_ActionCreateAgentPermittedActionEnum } from '@server/services/cbox/public/api/v1/resources/agent/types';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { CBox_PublicGetAgentActivityDataListItem, CBox_PublicGetAgentActivityDataResponse } from '@server/services/cbox/public/api/v1/resources/reports/types';
import { CBoxProfileDropOffRequestInfoComponent } from 'app/profile/requests/drop-off/dialogs/info/cbox-profile-dropoff-request-info.component';
import { CBoxProfilePickupRequestInfoComponent } from 'app/profile/requests/pickup/dialogs/info/cbox-profile-pickup-request-info.component';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { ToastService } from 'src/services/toast/toast.service';

@Component({
  selector: 'app-cbox-profile-agent-session-info-dialog',
  templateUrl: './cbox-profile-agent-session-info-dialog.component.html',
  styleUrls: ["./cbox-profile-agent-session-info-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogContent,
    MatDialogClose,
    TableModule,
    ProgressSpinnerModule
  ]
})

export class CBoxProfileAgentSessionInfoDialogComponent implements OnInit {

  loading = signal(false);
  sessionData = signal<CBox_PublicGetAgentActivityDataResponse | undefined>(undefined);

  actions = CBox_ActionCreateAgentPermittedActionEnum;

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private procedureId: string,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {}

  ngOnInit(): void {
    this.fetchSessionInfo();
  }

  getActionParcelCount(
    actionType:
        CBox_PublicGetAgentActivityDataListItem["actions"]["counts"][0]["type"]):
    number {
  return this.sessionData()?.actions.counts.find((a: CBox_PublicGetAgentActivityDataListItem["actions"]["counts"][0]) => a.type === actionType)?.count ?? 0;
}

  openOrderInfo(action: CBox_PublicGetAgentActivityDataResponse["actions"]["details"][0]): void {
    switch (action.type) {
      case CBox_ActionCreateAgentPermittedActionEnum.COURIER_PUT_ORDERS: {
        this.openPickUpRequestInfo(action.request);
        break;
      }

      case CBox_ActionCreateAgentPermittedActionEnum.COURIER_TAKE_ORDERS: {
        this.openDropOffRequestInfo(action.request);
        break;
      }

      case CBox_ActionCreateAgentPermittedActionEnum.COURIER_RETRIEVE_EXPIRED: {
        this.openPickUpRequestInfo(action.request);
      }
    }
  }

  openPickUpRequestInfo(request: CBox_PublicGetAgentActivityDataResponse["actions"]["details"][0]["request"]): void {
    this.dialog.open(CBoxProfileDropOffRequestInfoComponent, {
      data: {
        responseId: request.responseId,
        awb: request.awb
      },
      minWidth: "min(1200px, 100%)",
      autoFocus: false
    });
  }

  openDropOffRequestInfo(request: CBox_PublicGetAgentActivityDataResponse["actions"]["details"][0]["request"]): void {
    this.dialog.open(CBoxProfileDropOffRequestInfoComponent, {
      data: {
        responseId: request.responseId,
        awb: request.awb
      },
      minWidth: "min(1200px, 100%)",
      autoFocus: false
    });
  }

  private fetchSessionInfo(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.loading.set(true);
      this.api.get<CBox_PublicSuccessResponse<CBox_PublicGetAgentActivityDataResponse>>("backend/reports/agents/activity/" + this.procedureId).subscribe((session) => {
        this.sessionData.set(session.data);
        this.loading.set(false);
      }, (e: HttpErrorResponse) => {
        this.loading.set(false);
        handlePublicApiError(e, this.toastService);
      });
    }
  }

  private openOrderDetails(): void {

  }
}